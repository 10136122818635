<template>
  <div
    class="modal fade"
    id="modalUploadNhapVien"
    tabindex="-1"
    role="dialog"
    aria-labelledby="modalUploadNhapVienDetailLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content" v-loading="loading">
        <div class="text-center fs-22 fw-700 mt-3">
          <div class="flex justify-end mr-3">
            <button type="button" class="close bg-white border-0" @click="close">
              <span aria-hidden="true" class="fs-30 fw-500">&times;</span>
            </button>
          </div>
          <div>
            <span>PHIẾU ĐÁNH GIÁ DINH DƯỠNG CỦA BỆNH NHÂN NHẬP VIỆN</span>
          </div>
        </div>
        <div class="modal-body py-24">
          <div class="text-black">
            <!-- <div class="d-flex mb-3 mt-10">
      <div class="fs-14 fw-400 mr-4">Người nhập:
        <span class="fs-14 fw-700 ml-3">ThS BS Huấn Đặng</span>
      </div>
      <div class="fs-14 fw-400">Ngày nhập:
        <span class="fs-14 fw-700 ml-3">09:30 28/09/2022</span>
      </div>
            </div>-->

            <div class="fw-500 mb-3 flex items-center">
              <label class="w-28">Ngày đánh giá:</label>
              <input type="datetime-local" class="form--input w-48" v-model="form.date" />
            </div>

            <div>
              <label class="fs-14 fw-400 mb-3">1. Gần đây Ông/Bà có giảm cân:</label>
              <div class="flex mt-2 mb-3">
                <label
                  for="type11"
                  class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800"
                >
                  <span class="mr-2">Không giảm cân</span>
                  <input
                    type="radio"
                    id="type11"
                    :value="0"
                    @change="checkPoint()"
                    v-model="form.is_off_weight"
                    class="mr-2 cursor-pointer"
                  />0 điểm
                </label>
                <label
                  for="type12"
                  class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800"
                >
                  <span class="mr-2">Có hoặc Không chắc chắn</span>
                  <input
                    type="radio"
                    id="type12"
                    :value="2"
                    @change="checkPoint()"
                    v-model="form.is_off_weight"
                    class="mr-2 cursor-pointer"
                  />2 điểm
                </label>
              </div>

              <div v-if="this.form.is_off_weight === 2">
                <label class="fs-14 fw-400 mb-3">Nếu có, giảm bao nhiêu cân</label>
                <div class="flex mt-2 mb-3">
                  <label
                    for="type11"
                    class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800"
                  >
                    <span class="mr-2">1-5 kg</span>
                    <input
                      type="radio"
                      id="type11"
                      :value="0"
                      v-model="form.weight_loss"
                      class="mr-2 cursor-pointer"
                    />0 điểm
                  </label>
                  <label
                    for="type12"
                    class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800"
                  >
                    <span class="mr-2">≥ 6kg/không chắc chắn</span>
                    <input
                      type="radio"
                      id="type12"
                      :value="2"
                      v-model="form.weight_loss"
                      class="mr-2 cursor-pointer"
                    />2 điểm
                  </label>
                </div>
              </div>

              <label class="fs-14 fw-400 mb-3">2. Ông/Bà có ăn uống kém do giảm ngon miệng:</label>
              <div class="flex mt-2 mb-3">
                <label
                  for="type11"
                  class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800"
                >
                  <span class="mr-2">Không</span>
                  <input
                    type="radio"
                    id="type11"
                    :value="0"
                    @change="checkPoint()"
                    v-model="form.anorexia"
                    class="mr-2 cursor-pointer"
                  />0 điểm
                </label>
                <label
                  for="type12"
                  class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800"
                >
                  <span class="mr-2">Có</span>
                  <input
                    type="radio"
                    id="type12"
                    :value="2"
                    v-model="form.anorexia"
                    @change="checkPoint()"
                    class="mr-2 cursor-pointer"
                  />2 điểm
                </label>
              </div>

              <div class="mt-2 d-flex mb-4">
                <label class="fs-14 fw-700 mb-3 mr-4">Phân loại</label>
                <div>
                  <label
                    for="type11"
                    class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800 mb-3"
                  >
                    <input
                      type="radio"
                      id="type11"
                      :checked="result === 0"
                      name="drone"
                      class="mr-2 pointer-events-none"
                    />Không nguy cơ (tổng điểm MST = 0 hoặc MST = 1)
                  </label>

                  <label
                    for="type12"
                    class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800"
                  >
                    <input
                      name="drone"
                      type="radio"
                      :checked="result >= 2"
                      id="type12"
                      class="mr-2 pointer-events-none"
                    />Có nguy cơ (tổng điểm MST >= 2 --> đánh giá tiếp bảng dưới)
                  </label>
                </div>
              </div>

              <div>
                <p
                  class="fs-14 fw-400 fst-italic"
                >(Lưu ý: BN chấn thương nặng, nằm ICU, người cao tuổi > 65 tuổi mắc bệnh đều tính là đối tượng có nguy cơ phải đánh giá SGA)</p>
                <p
                  class="fs-14 fw-700 text-black"
                >Đánh giá tổng thể tình trạng dinh dưỡng theo chủ quan (SGA-Subjective Global Assessment)</p>

                <label class="fs-14 fw-400 mb-3">1. Sụt cân trong 3-6 tháng trước khi nhập viện:</label>
                <div class="mt-2 mb-3">
                  <label
                    for="type14"
                    class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800 mb-3"
                  >
                    <span class="mr-2">Không</span>
                    <input
                      type="radio"
                      v-model="noCheck"
                      :value="1"
                      id="type14"
                      @change="onchange1"
                      name="type14"
                      class="mr-2 cursor-pointer"
                    />
                  </label>
                  <label
                    for="type14"
                    class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800"
                  >
                    <span class="mr-15">Có</span>
                    <input
                      type="radio"
                      v-model="check"
                      @change="onchange2"
                      :value="1"
                      name="type14"
                      id="type14"
                      class="mr-2 cursor-pointer"
                    />
                    <div v-if="check" class="ml-4">
                      <input
                        class="form--input w-20 mr-2"
                        v-model="form.weight_state_kg"
                        type="text"
                      />kg /
                      <input
                        v-model="form.weight_state_month"
                        class="form--input w-20 mx-2"
                        type="text"
                      />Tháng
                      <input
                        v-model="form.weight_state_guyg"
                        class="form--input w-20 mx-2"
                        type="text"
                      />%
                    </div>
                  </label>
                </div>

                <div class="mb-3">
                  <label class="fs-14 fw-400 mb-3">2. Ăn uống trong 2 tuần trước khi vào viện:</label>
                  <div class="row mt-2 mb-3">
                    <div>
                      <label
                        for="type11"
                        class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800 mb-3"
                      >
                        <span class="mr-2">Bình thường</span>
                        <input
                          type="radio"
                          id="type11"
                          :value="1"
                          name="type11"
                          v-model="form.eating_state"
                          @change="checkEating1"
                          class="mr-2 cursor-pointer"
                        />
                      </label>
                    </div>
                    <div class="col-6">
                      <label
                        for="type11"
                        class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800 mb-3"
                      >
                        <span class="mr-2">Có giảm sút</span>
                        <input
                          type="radio"
                          id="type11"
                          :value="1"
                          name="type11"
                          v-model="checkEatingDown"
                          class="mr-2 cursor-pointer"
                        />
                      </label>
                    </div>
                    <div class="col-6" v-if="checkEating === 1 || checkEatingDown === 1">
                      <div class="mb-3">
                        <label
                          for="type19"
                          class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800"
                        >
                          <span class="mr-2">Trên 50% so với bình thường</span>
                          <input
                            type="radio"
                            id="type19"
                            :value="2"
                            v-model="form.eating_state"
                            class="mr-2 cursor-pointer"
                          />
                        </label>
                      </div>
                      <div>
                        <label
                          for="type19"
                          class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800"
                        >
                          <span class="mr-2">Dưới 50% so với bình thường</span>
                          <input
                            type="radio"
                            id="type19"
                            :value="3"
                            v-model="form.eating_state"
                            class="mr-2 cursor-pointer"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <label class="fs-14 fw-400 mb-3">3. Khám (0 = không; 1 = nhẹ; 2 = vừa; 3 = nặng):</label>
                <div class="mt-2 mb-3 row">
                  <div class="col">
                    <div class="mb-3">Lớp mỡ dưới da vùng cơ mặt; cơ nhị đầu hay cơ tam đầu</div>
                    <div>Cơ vùng thái dương; delta; cơ ngực; tứ đầu đùi</div>
                  </div>
                  <div class="col">
                    <div class="mb-3">
                      <el-select class="w-20 mr-2" v-model="form.examination_result_01">
                        <el-option
                          v-for="item in options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>Teo lớp mỡ dưới da
                    </div>
                    <div class="mb-3">
                      <el-select class="w-20 mr-2" v-model="form.examination_result_02">
                        <el-option
                          v-for="item in options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>Teo cơ
                    </div>
                    <div class="mb-3">
                      <el-select class="w-20 mr-2" v-model="form.examination_result_03">
                        <el-option
                          v-for="item in options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>Phù chi/cột sống thắt lưng
                    </div>
                    <div class="mb-3">
                      <el-select class="w-20 mr-2" v-model="form.examination_result_04">
                        <el-option
                          v-for="item in options"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        ></el-option>
                      </el-select>Báng bụng
                    </div>
                  </div>
                </div>

                <label class="fs-14 fw-400 mb-3">4. Phân loại SGA</label>
                <div>
                  <p class="fst-italic fs-14 fw-400">Cách phân loại</p>
                  <label for="type15" class="d-flex align-items-center mr-4 fs-14 fw-400 mb-3">
                    <input
                      type="radio"
                      id="type15"
                      :value="1"
                      v-model="form.sga"
                      class="mr-2 cursor-pointer"
                    />SGA – A: Dinh dưỡng tốt
                  </label>
                  <label for="type15" class="d-flex align-items-center mr-4 fs-14 fw-400 mb-3">
                    <input
                      type="radio"
                      id="type15"
                      :value="2"
                      v-model="form.sga"
                      class="mr-2 cursor-pointer"
                    />SGA – B: Nghi ngờ SĐ hay SĐ vừa
                  </label>
                  <label for="type15" class="d-flex align-items-center mr-4 fs-14 fw-400 mb-3">
                    <input
                      type="radio"
                      id="type15"
                      :value="3"
                      v-model="form.sga"
                      class="mr-2 cursor-pointer"
                    />SGA – C: SĐ nặng
                  </label>
                </div>

                <label class="fs-14 fw-400 mb-3">5. Xác định nhu cầu dinh dưỡng (năng lượng và đạm)</label>
                <div class="mt-2 mb-3">
                  <label
                    for="type11"
                    class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800 mb-3"
                  >
                    <input
                      v-model="other1"
                      @change="inputOther1"
                      :value="1"
                      type="radio"
                      id="type11"
                      name="type11"
                      class="mr-2 cursor-pointer"
                    />1,45-1,50m
                    <span class="ml-5 mr-5">47-49kg/1500-1600kcal</span>55-58g đạm
                  </label>
                  <label
                    for="type11"
                    class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800 mb-3"
                  >
                    <input
                      type="radio"
                      id="type11"
                      name="type11"
                      v-model="other2"
                      @change="inputOther2"
                      :value="1"
                      class="mr-2 cursor-pointer"
                    />1,51-1,55m
                    <span class="ml-5 mr-5">50-52kg/1600-1700kcal</span>59-62g đạm
                  </label>
                  <label
                    for="type11"
                    class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800 mb-3"
                  >
                    <input
                      type="radio"
                      id="type11"
                      v-model="other3"
                      name="type11"
                      @change="inputOther3"
                      :value="1"
                      class="mr-2 cursor-pointer"
                    />1,56-1,60m
                    <span class="ml-5 mr-5">54-56kg/1700-1800kcal</span>63-66g đạm
                  </label>
                  <label
                    for="type11"
                    class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800 mb-3"
                  >
                    <input
                      type="radio"
                      id="type11"
                      :value="1"
                      class="mr-2 cursor-pointer"
                      name="type11"
                      v-model="other4"
                      @change="inputOther4"
                    />1,61-1,65m
                    <span class="ml-5 mr-5">57-59kg/1800-1900kcal</span>67-70g đạm
                  </label>
                  <label
                    for="type11"
                    class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800 mb-3"
                  >
                    <input
                      type="radio"
                      id="type11"
                      :value="1"
                      class="mr-2 cursor-pointer"
                      name="type11"
                      v-model="other5"
                      @change="inputOther5"
                    />1,61-1,65m
                    <span class="ml-5 mr-5">60-62kg/1900-2000kcal</span>71-74g đạm
                  </label>
                  <label
                    for="type11"
                    class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800 mb-3"
                  >
                    <input
                      type="radio"
                      id="type11"
                      :value="1"
                      class="mr-2 cursor-pointer"
                      name="type11"
                      v-model="other6"
                      @change="inputOther6"
                    />1,71-1,75m
                    <span class="ml-5 mr-5">63-65kg/2000-2100kcal</span>75-78g đạm
                  </label>
                </div>
                <div class="mt-3">
                  <label
                    for="type12"
                    class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800 mb-3"
                  >
                    <input
                      :value="1"
                      v-model="other"
                      class="form--input w-20 mr-2 cursor-pointer"
                      type="radio"
                      @change="clearOther"
                    />
                    <span class="mr-2">Hoặc nhu cầu khác:</span>
                    <div v-if="other">
                      <input
                        class="form--input w-20 mr-2"
                        type="text"
                        v-model="form.nutritional_kcal"
                      />kcal;
                      <input
                        class="form--input w-20 mx-2"
                        type="text"
                        v-model="form.nutritional_protein"
                      />g đạm;
                      <input v-model="form.nutritional_sugar" class="form--input w-20 mx-2" />g đường;
                      <input class="form--input w-20 mx-2" v-model="form.nutritional_fat " />g béo;
                    </div>
                  </label>
                </div>

                <div class="mb-3">
                  <label class="fs-14 fw-400 mb-3">6. Kế hoạch dinh dưỡng cho người bệnh</label>
                  <div class="row mt-2 mb-3">
                    <div class="col">
                      <label
                        for="type11"
                        class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800 mb-3"
                      >
                        <el-checkbox
                          class="mr-2"
                          v-model="form.nutritional_option_01"
                          :true-label="1"
                          :false-label="0"
                        />
                        <span class="mr-2">Chỉ định mã chế độ ăn</span>
                      </label>
                      <label
                        for="type12"
                        class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800"
                      >
                        <el-checkbox
                          class="mr-2"
                          :true-label="1"
                          :false-label="0"
                          v-model="form.nutritional_option_03"
                        />
                        <span class="mr-2">Bổ sung thức uống DD</span>
                      </label>
                    </div>
                    <div class="col">
                      <label
                        for="type11"
                        class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800 mb-3"
                      >
                        <el-checkbox
                          class="mr-2"
                          :true-label="1"
                          :false-label="0"
                          v-model="form.nutritional_option_02"
                        />
                        <span class="mr-2">Qua ống thông</span>
                      </label>
                      <label
                        for="type12"
                        class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800"
                      >
                        <el-checkbox
                          class="mr-2"
                          :true-label="1"
                          :false-label="0"
                          v-model="form.nutritional_option_04"
                        />
                        <span class="mr-2">Qua tĩnh mạch</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="flex justify-end mt-3">
            <button
              :disabled="is_loading"
              type="button"
              class="btn bg-pri text-white"
              @click="submit"
            >Lưu</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

  <script>
import appUtils from '../../../utils/appUtils'
export default {
  name: 'NhapVienUpdate',
  components: {},
  props: ['data', 'is_loading'],
  data () {
    return {
      loading: false,
      other: null,
      other1: null,
      other2: null,
      other3: null,
      other4: null,
      other5: null,
      other6: null,
      checkEating: null,
      result: 0,
      checkEatingDown: null,
      check: null,
      noCheck: null,
      form: {
        name: 'PHIẾU ĐÁNH GIÁ DINH DƯỠNG CỦA BỆNH NHÂN NHẬP VIỆN',
        date: appUtils.formatInputDateTime(),
        is_off_weight: 0,
        weight_loss: null,
        anorexia: 0,
        weight_state_kg: '',
        weight_state_guyg: '',
        weight_state_month: '',
        sga: 0,
        examination_result_01: '',
        examination_result_02: '',
        examination_result_03: '',
        examination_result_04: '',
        nutritional_option_01: '',
        nutritional_option_02: '',
        nutritional_option_03: '',
        nutritional_option_04: '',
        nutritional_kcal: '',
        nutritional_sugar: '',
        nutritional_protein: '',
        nutritional_fat: '',
        eating_state: null
      },
      options: [
        {
          value: 0,
          label: '0'
        },
        {
          value: 1,
          label: '1'
        },
        {
          value: 2,
          label: '2'
        },
        {
          value: 3,
          label: '3'
        }
      ]
    }
  },
  mounted () {},
  watch: {
    data () {
      this.form.name = this.data.name
      this.form.date = this.data.date
      this.form.is_off_weight = this.data.is_off_weight
      this.form.weight_loss = this.data.weight_loss
      this.form.anorexia = this.data.anorexia
      this.form.weight_state_kg = this.data.weight_state_kg
      this.form.weight_state_guyg = this.data.weight_state_guyg
      this.form.sga = this.data.sga
      this.form.nutritional_option = this.data.nutritional_option
      this.form.nutritional_kcal = this.data.nutritional_kcal
      this.form.nutritional_sugar = this.data.nutritional_sugar
      this.form.nutritional_protein = this.data.nutritional_protein
      this.form.nutritional_fat = this.data.nutritional_fat
      this.form.eating_state = this.data.eating_state
      this.result =
        parseInt(this.data.is_off_weight) + parseInt(this.data.anorexia)
      this.form.examination_result_01 = this.data.examination_result_01
      this.form.examination_result_02 = this.data.examination_result_02
      this.form.examination_result_03 = this.data.examination_result_03
      this.form.examination_result_04 = this.data.examination_result_04
      this.form.nutritional_option_01 = this.data.nutritional_option_01
      this.form.nutritional_option_02 = this.data.nutritional_option_02
      this.form.nutritional_option_03 = this.data.nutritional_option_03
      this.form.nutritional_option_04 = this.data.nutritional_option_04
      if (
        this.data.weight_state_month ||
        this.data.weight_state_kg ||
        this.data.weight_state_guyg
      ) {
        this.check = 1
      } else {
        this.noCheck = 1
      }
      if (this.data.eating_state === 2 || this.data.eating_state === 3) {
        this.checkEatingDown = 1
      }
      if (
        this.data.nutritional_kcal === '1500-1600' &&
        this.data.nutritional_protein === '55-58'
      ) {
        this.other1 = 1
      }
      if (
        this.data.nutritional_kcal === '1600-1700' &&
        this.data.nutritional_protein === '59-62'
      ) {
        this.other2 = 1
      }
      if (
        this.data.nutritional_kcal === '1700-1800' &&
        this.data.nutritional_protein === '63-66'
      ) {
        this.other3 = 1
      }
      if (
        this.data.nutritional_kcal === '1800-1900' &&
        this.data.nutritional_protein === '67-70'
      ) {
        this.other4 = 1
      }
      if (
        this.data.nutritional_kcal === '1900-2000' &&
        this.data.nutritional_protein === '71-74'
      ) {
        this.other5 = 1
      }
      if (
        this.data.nutritional_kcal === '2000-2100' &&
        this.data.nutritional_protein === '75-78'
      ) {
        this.other6 = 1
      }
    }
  },
  methods: {
    onchange1 () {
      if (this.noCheck) {
        this.form.weight_state_month = ''
        this.form.weight_state_kg = ''
        this.form.weight_state_guyg = ''
        this.check = null
      }
    },
    onchange2 () {
      this.noCheck = null
    },
    submit () {
      this.$emit('onUpdate', this.form)
    },
    close () {
      const show = false
      window.$('#modalUploadNhapVien').modal(show ? 'show' : 'hide')
    },
    inputOther1 () {
      this.other = null
      this.form.nutritional_kcal = '1500-1600'
      this.form.nutritional_protein = '55-58'
    },
    inputOther2 () {
      this.other = null
      this.form.nutritional_kcal = '1600-1700'
      this.form.nutritional_protein = '59-62'
    },
    inputOther3 () {
      this.other = null
      this.form.nutritional_kcal = '1700-1800'
      this.form.nutritional_protein = '63-66'
    },
    inputOther4 () {
      this.other = null
      this.form.nutritional_kcal = '1800-1900'
      this.form.nutritional_protein = '67-70'
    },
    inputOther5 () {
      this.other = null
      this.form.nutritional_kcal = '1900-2000'
      this.form.nutritional_protein = '71-74'
    },
    inputOther6 () {
      this.other = null
      this.form.nutritional_kcal = '2000-2100'
      this.form.nutritional_protein = '75-78'
    },
    clearOther () {
      this.other1 = null
      this.other2 = null
      this.other3 = null
      this.other4 = null
      this.other5 = null
      this.other6 = null
      this.form.nutritional_kcal = ''
      this.form.nutritional_protein = ''
    },
    checkPoint () {
      this.result =
        parseInt(this.form.is_off_weight) + parseInt(this.form.anorexia)
      if (this.form.is_off_weight === 0) {
        this.form.weight_loss = null
      }
    },
    checkEating1 () {
      this.checkEating = null
      this.checkEatingDown = null
      this.form.eating_state = 1
    },
    checkEating2 () {
      this.checkEating = 1
    }
  }
}
</script>

<style scoped>
.mr-15 {
  margin-right: 30px;
}
.border-box {
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
}
.border-bottom-item {
  border-bottom: 1px solid black;
}
.border-right-item {
  border-right: 1px solid black;
}
.tablePrint {
  font-family: "Tinos", serif;
  color: black;
}
.box {
  border: 1px solid black;
}
</style>
