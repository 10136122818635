<template>
  <div class="text-black container">
    <!-- <div class="d-flex mb-3 mt-10">
      <div class="fs-14 fw-400 mr-4">Người nhập:
        <span class="fs-14 fw-700 ml-3">ThS BS Huấn Đặng</span>
      </div>
      <div class="fs-14 fw-400">Ngày nhập:
        <span class="fs-14 fw-700 ml-3">09:30 28/09/2022</span>
      </div>
    </div>-->
    <div class="fw-500 mb-3 flex items-center">
      <label class="w-28">Ngày đánh giá:</label>
      <input type="datetime-local" class="form--input w-48" v-model="form.date" />
    </div>

    <div class="mt-3">
      <div>
        <div class="fs-14 fw-500 txt-grey-900">1. TẦM SOÁT NGUY CƠ DINH DƯỠNG:</div>
        <div class="mt-1">Các chỉ số cần đánh giá</div>
      </div>
      <div class="row mt-3">
        <div class="col-md-9">
          <label for="type11" class="d-flex align-items-center mr-4 fs-14 txt-grey-800">
            <el-checkbox
              class="mr-2"
              :true-label="1"
              :false-label="0"
              @change="totalScore"
              v-model="form.nutrition_control_ind1"
            ></el-checkbox>Cân nặng/ tuổi hoặc BMI = -1 SD hoặc teo cơ, mất cơ, mất lớp mỡ dưới da (quan sát thấy)
          </label>
        </div>
        <div class="col-md-3 row">
          <div class="col-4">
            <div class="font-bold">Chiều cao:</div>
          </div>
          <div class="col-5">
            <input class="mx-2 form--input" v-model="form.height" @change="calcNumb" />
          </div>
          <div class="col-2 mt-1 font-bold">cm</div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-9">
          <label for="type12" class="d-flex align-items-center mr-4 fs-14 txt-grey-800">
            <el-checkbox
              class="mr-2"
              v-model="form.nutrition_control_ind2"
              :true-label="1"
              :false-label="0"
              @change="totalScore"
            ></el-checkbox>Sụt cân hoặc không lên cân trong 3 tháng vừa
            qua
          </label>
        </div>
        <div class="col-md-3 row">
          <div class="col-4">
            <div class="font-bold">Cân nặng:</div>
          </div>
          <div class="col-5">
            <input class="mx-2 form--input" v-model="form.weight" @change="calcNumb" />
          </div>
          <div class="col-2 mt-1 font-bold">kg</div>
        </div>
        <div></div>
      </div>
      <div class="row mt-2">
        <div class="col-md-9">
          <label for="type13" class="d-flex align-items-center mr-4 fs-14 txt-grey-800">
            <el-checkbox
              class="mr-2"
              v-model="form.nutrition_control_ind3"
              :true-label="1"
              @change="totalScore"
              :false-label="0"
            ></el-checkbox>Lượng ăn 50% nhu cầu trong 1 tuần qua
          </label>
        </div>
        <div class="col-md-3 row pointer-events-none">
          <div class="col-4">
            <div class="font-bold">BMI:</div>
          </div>
          <div class="col-5">
            <input class="mx-2 form--input" :value="getNumb()" />
          </div>
          <div class="col-2"></div>
        </div>
      </div>
      <label for="type14" class="d-flex align-items-center mr-4 fs-14 txt-grey-800 mt-2">
        <el-checkbox
          class="mr-2"
          :true-label="1"
          :false-label="0"
          @change="totalScore"
          v-model="form.nutrition_control_ind4"
        ></el-checkbox>Có bệnh lý nền gây kém hấp thụ hoặc hạn
        chế lượng ăn
        đường tiêu hóa (Tiêu chảy, nhiễm trùng nặng, suy tim, suy hô hấp)
      </label>
      <label for="type15" class="d-flex align-items-center mr-4 fs-14 txt-grey-800 mt-3">
        <el-checkbox
          class="mr-2"
          v-model="form.nutrition_control_ind5"
          :true-label="1"
          :false-label="0"
          @change="totalScore"
        ></el-checkbox>Có bệnh gây rối loạn chuyển hóa dinh dưỡng
        (gan thận,
        nội tiết, chuyển hóa)
      </label>
    </div>
    <div class="mt-3 row">
      <label
        class="col-lg-4 fs-14 fw-700 txt-grey-900"
      >Tổng điểm (có tính 1 điểm, không tính 0 điểm)</label>
      <div class="col-lg-1 col-md-3">
        <!-- <input
          type="text"
          class="form--input fs-14 fw-500 txt-grey-900"
          placeholder=""
        />-->
      </div>
    </div>

    <div class="mt-3">
      <div>
        <div class="fs-14 fw-500 txt-grey-900">2. KẾ HOẠCH DINH DƯỠNG</div>
      </div>
      <label
        for="type21"
        class="d-flex align-items-center mr-4 fs-14 txt-grey-800 mt-3 pointer-events-none"
      >
        <input
          type="radio"
          id="type21"
          :checked="form.nutrition_control_option === 0"
          class="mr-2 cursor-pointer"
        />0 ĐIỂM: Không có nguy cơ SDD cấp, chế độ ăn bình thường, đánh
        giá sau 1 tháng
      </label>
      <label
        for="type22"
        class="d-flex align-items-center mr-4 fs-14 txt-grey-800 mt-3 pointer-events-none"
      >
        <input
          type="radio"
          :checked="form.nutrition_control_option === 1 || form.nutrition_control_option === 2"
          id="type22"
          class="mr-2 cursor-pointer"
        />1-2 ĐIỂM: Nguy cơ SDD nhẹ. Hướng dẫn chế độ ăn, đánh giá lại
        sau 2 tuần
      </label>
      <label
        for="type23"
        class="d-flex align-items-center mr-4 fs-14 txt-grey-800 mt-3 pointer-events-none"
      >
        <input
          type="radio"
          id="type23"
          :checked="form.nutrition_control_option === 3"
          class="mr-2 cursor-pointer"
        />3 ĐIỂM: Nguy cơ SDD trung bình, chuyển khám dinh dưỡng đánh giá
        sau 1-2 tuần
      </label>
      <label
        for="type24"
        class="d-flex align-items-center mr-4 fs-14 txt-grey-800 mt-3 pointer-events-none"
      >
        <input
          type="radio"
          id="type24"
          :checked="form.nutrition_control_option === 4 || form.nutrition_control_option === 5"
          class="mr-2 cursor-pointer"
        />4-5 ĐIỂM: Nguy cơ SDD nặng. Hội chẩn dinh dưỡng và có chế độ ăn
        phù hợp, đánh giá và hội chẩn lại mỗi 3-5 ngày
      </label>
    </div>
    <div class="flex justify-end mt-3">
      <button
        :disabled="loadingList"
        type="button"
        class="btn bg-pri text-white"
        @click="submit"
      >Lưu</button>
    </div>
  </div>
</template>

<script>
import appUtils from '../../../utils/appUtils'
export default {
  props: ['loadingList'],
  name: 'PhieuDangNamVien',
  data () {
    const defaultForm = {
      weight: null,
      height: null,
      name: 'PHIẾU ĐÁNH GIÁ DINH DƯỠNG CỦA BỆNH NHÂN NẰM VIỆN',
      nutrition_control_ind1: 0,
      nutrition_control_ind2: 0,
      nutrition_control_ind3: 0,
      nutrition_control_ind4: 0,
      nutrition_control_ind5: 0,
      nutrition_control_option: 0,
      date: appUtils.formatInputDateTime()
    }

    return {
      result: null,
      defaultForm,
      form: defaultForm
    }
  },
  mounted () {
    //
  },
  methods: {
    getNumb () {
      if (this.result) {
        const r = Math.round(this.result)
        const item = r.toString().length
        if (item === 2) {
          return String(this.result).slice(0, 5)
        }
        if (item === 1) {
          return String(this.result).slice(0, 4)
        }
        if (item === 3) {
          return String(this.result).slice(0, 6)
        }
        if (item === 4) {
          return String(this.result).slice(0, 7)
        }
      }
    },
    totalScore () {
      this.form.nutrition_control_option =
        this.form.nutrition_control_ind1 +
        this.form.nutrition_control_ind2 +
        this.form.nutrition_control_ind3 +
        this.form.nutrition_control_ind4 +
        this.form.nutrition_control_ind5
    },
    calcNumb () {
      const meters = parseInt(this.form.height) / 100
      this.result = parseInt(this.form.weight) / (meters * meters)
    },
    submit () {
      const self = this
      self.$emit('onCreate', this.form)
      this.result = null
      this.form = this.defaultForm
    }
  }
}
</script>

<style scoped>
</style>
