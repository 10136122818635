<template>
  <div>
    <div
      class="modal fade overflow-x-hidden"
      id="modalPhieuNamVienDetail"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalPhieuDinhDuongDetailLabel"
      aria-hidden="true"
      style="overflow-y: auto"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content" v-loading="loading">
          <div
            class="sticky top-0 flex justify-content-end bg-white text-base font-semibold z-50 mb-3 mt-2 print:hidden px-4"
          >
            <div class="flex items-center" v-if="mode === 'update'">
              <button
                :disabled="is_loading"
                type="button"
                class="btn border-none text-black px-3 mx-2 fs-14 fw-500"
                @click="onUpdate"
              >
                Lưu
              </button>
              <button
                v-if="!is_loading"
                type="button"
                @click="closeUpdate"
                class="close bg-white border-0"
              >
                <span aria-hidden="true" class="fs-30 fw-500">&times;</span>
              </button>
            </div>
            <div class="flex items-center" v-else>
              <button
                :disabled="loading"
                class="btn border-none text-black px-3 mx-2 fs-14 fw-500"
                @click="processPrintPDF()"
              >
                <div class="loading">
                  <div v-if="loading" class="spinner-border mr-2" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill="#03267A"
                    d="M9.021 1.772a2.445 2.445 0 0 1 3.458 3.457l-.272.271l.086.086a2 2 0 0 1 0 2.828l-1.44 1.44a.5.5 0 0 1-.707-.707l1.44-1.44a1 1 0 0 0 0-1.414l-.086-.086l-5.646 5.647a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.595-.643l1.25-3.75a.5.5 0 0 1 .12-.195l6.625-6.625Zm2.75.707a1.445 1.445 0 0 0-2.042 0L3.187 9.02l-.925 2.774l2.982-.745l6.527-6.527a1.445 1.445 0 0 0 0-2.043ZM4.967 15c-1.235 0-2.208-.566-2.855-1.107l1.284-.32a3.2 3.2 0 0 0 1.57.427c.406 0 .824-.164 1.27-.457c.447-.293.878-.687 1.314-1.095l.113-.107c.385-.361.784-.737 1.166-.996c.408-.278.95-.53 1.517-.311c.376.144.62.41.791.736c.161.306.272.688.38 1.105c.05.19.133.374.23.498a.46.46 0 0 0 .114.112c.025.015.036.015.039.015c.122 0 .331-.082.63-.286c.185-.126.344-.252.502-.379c.098-.078.197-.157.302-.235c.279-.209.52-.349.697-.438a3.05 3.05 0 0 1 .278-.124l.02-.008l.007-.002l.003-.001h.001l.16.473l-.159-.474a.5.5 0 0 1 .321.947l-.004.002a2.141 2.141 0 0 0-.175.08c-.13.064-.321.174-.55.346c-.059.043-.135.104-.223.174c-.181.143-.409.324-.618.466c-.31.212-.741.46-1.192.46c-.436 0-.751-.265-.946-.517a2.435 2.435 0 0 1-.405-.858c-.11-.423-.196-.7-.296-.89c-.09-.168-.17-.232-.266-.27c-.082-.03-.245-.033-.595.206c-.313.212-.655.533-1.058.912l-.1.093c-.431.405-.92.855-1.449 1.202c-.53.348-1.14.621-1.818.621Z"
                  />
                </svg>
                {{ $t("multidisciplinary_board.btn_sign") }}
              </button>
              <button
                class="btn border-none text-black px-3 mx-2 fs-14 fw-500"
                @click="onPrintNe"
              >
                <div class="loading">
                  <div v-if="loading" class="spinner-border mr-2" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 14H17C17.2652 14 17.5196 13.8946 17.7071 13.7071C17.8946 13.5196 18 13.2652 18 13V7C18 6.73478 17.8946 6.48043 17.7071 6.29289C17.5196 6.10536 17.2652 6 17 6H3C2.73478 6 2.48043 6.10536 2.29289 6.29289C2.10536 6.48043 2 6.73478 2 7V13C2 13.2652 2.10536 13.5196 2.29289 13.7071C2.48043 13.8946 2.73478 14 3 14H4V9H16V14ZM4 4V0H16V4H17C17.7956 4 18.5587 4.31607 19.1213 4.87868C19.6839 5.44129 20 6.20435 20 7V13C20 13.7956 19.6839 14.5587 19.1213 15.1213C18.5587 15.6839 17.7956 16 17 16H16V20H4V16H3C2.20435 16 1.44129 15.6839 0.87868 15.1213C0.31607 14.5587 0 13.7956 0 13L0 7C0 6.20435 0.31607 5.44129 0.87868 4.87868C1.44129 4.31607 2.20435 4 3 4H4ZM6 18H14V11H6V18ZM6 4H14V2H6V4Z"
                    fill="#03267A"
                  />
                </svg>
                {{ $t("multidisciplinary_board.lbl_print") }}
              </button>
              <button
                class="btn border-none text-black px-3 mx-2 fs-14 fw-500"
                @click="ModalUpload()"
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.75 8.99995C15.5511 8.99995 15.3603 9.07897 15.2197 9.21962C15.079 9.36028 15 9.55104 15 9.74995V14.25C15 14.4489 14.921 14.6396 14.7803 14.7803C14.6397 14.9209 14.4489 15 14.25 15H3.75C3.55109 15 3.36032 14.9209 3.21967 14.7803C3.07902 14.6396 3 14.4489 3 14.25V3.74995C3 3.55104 3.07902 3.36028 3.21967 3.21962C3.36032 3.07897 3.55109 2.99995 3.75 2.99995H8.25C8.44891 2.99995 8.63968 2.92094 8.78033 2.78028C8.92098 2.63963 9 2.44887 9 2.24995C9 2.05104 8.92098 1.86028 8.78033 1.71962C8.63968 1.57897 8.44891 1.49995 8.25 1.49995H3.75C3.15326 1.49995 2.58097 1.73701 2.15901 2.15896C1.73705 2.58092 1.5 3.15322 1.5 3.74995V14.25C1.5 14.8467 1.73705 15.419 2.15901 15.8409C2.58097 16.2629 3.15326 16.5 3.75 16.5H14.25C14.8467 16.5 15.419 16.2629 15.841 15.8409C16.2629 15.419 16.5 14.8467 16.5 14.25V9.74995C16.5 9.55104 16.421 9.36028 16.2803 9.21962C16.1397 9.07897 15.9489 8.99995 15.75 8.99995ZM4.5 9.56995V12.75C4.5 12.9489 4.57902 13.1396 4.71967 13.2803C4.86032 13.4209 5.05109 13.5 5.25 13.5H8.43C8.5287 13.5005 8.62655 13.4816 8.71793 13.4443C8.80931 13.407 8.89242 13.352 8.9625 13.2825L14.1525 8.08495L16.2825 5.99995C16.3528 5.93023 16.4086 5.84728 16.4467 5.75589C16.4847 5.66449 16.5043 5.56646 16.5043 5.46745C16.5043 5.36845 16.4847 5.27042 16.4467 5.17902C16.4086 5.08763 16.3528 5.00468 16.2825 4.93495L13.1025 1.71745C13.0328 1.64716 12.9498 1.59136 12.8584 1.55329C12.767 1.51521 12.669 1.49561 12.57 1.49561C12.471 1.49561 12.373 1.51521 12.2816 1.55329C12.1902 1.59136 12.1072 1.64716 12.0375 1.71745L9.9225 3.83995L4.7175 9.03745C4.64799 9.10753 4.593 9.19065 4.55567 9.28202C4.51835 9.3734 4.49943 9.47125 4.5 9.56995V9.56995ZM12.57 3.30745L14.6925 5.42995L13.6275 6.49495L11.505 4.37245L12.57 3.30745ZM6 9.87745L10.4475 5.42995L12.57 7.55245L8.1225 12H6V9.87745Z"
                    fill="#03267A"
                  />
                </svg>
                {{ $t("multidisciplinary_board.lbl_modify_short") }}
              </button>
              <button
                type="button"
                id="closeModal"
                class="close bg-white border-0"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" class="fs-30 fw-500">&times;</span>
              </button>
            </div>
          </div>
          <div v-if="mode === 'update'" class="text-center font-bold text-xl">
            <span>PHIẾU ĐÁNH GIÁ DINH DƯỠNG CỦA BỆNH NHÂN NẰM VIỆN</span>
          </div>
          <div class="modal-body pb-24 pt-5">
            <div class="text-black container" v-if="mode === 'update'">
              <div class="fw-500 mb-3 flex items-center">
                <label class="w-28">Ngày đánh giá:</label>
                <input
                  type="datetime-local"
                  class="form--input w-48"
                  v-model="formatInputDateTime"
                />
              </div>

              <div class="mt-3">
                <div>
                  <div class="fs-14 fw-500 txt-grey-900">
                    1. TẦM SOÁT NGUY CƠ DINH DƯỠNG:
                  </div>
                  <div class="mt-1">Các chỉ số cần đánh giá</div>
                </div>
                <div class="row mt-3">
                  <div class="col-md-9">
                    <label
                      for="type11"
                      class="d-flex align-items-center mr-4 fs-14 txt-grey-800"
                    >
                      <el-checkbox
                        class="mr-2"
                        :true-label="1"
                        :false-label="0"
                        @change="totalScore"
                        v-model="form.nutrition_control_ind1"
                      ></el-checkbox
                      >Cân nặng/ tuổi hoặc BMI = -1 SD hoặc teo cơ, mất cơ, mất
                      lớp mỡ dưới da (quan sát thấy)
                    </label>
                  </div>
                  <div class="col-md-3 row">
                    <div class="col-4">
                      <div class="font-bold">Chiều cao:</div>
                    </div>
                    <div class="col-5">
                      <input
                        class="mx-2 form--input"
                        v-model="form.height"
                        @change="calcNumb"
                      />
                    </div>
                    <div class="col-2 mt-1 font-bold">cm</div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-9">
                    <label
                      for="type12"
                      class="d-flex align-items-center mr-4 fs-14 txt-grey-800"
                    >
                      <el-checkbox
                        class="mr-2"
                        v-model="form.nutrition_control_ind2"
                        :true-label="1"
                        :false-label="0"
                        @change="totalScore"
                      ></el-checkbox
                      >Sụt cân hoặc không lên cân trong 3 tháng vừa qua
                    </label>
                  </div>
                  <div class="col-md-3 row">
                    <div class="col-4">
                      <div class="font-bold">Cân nặng:</div>
                    </div>
                    <div class="col-5">
                      <input
                        class="mx-2 form--input"
                        v-model="form.weight"
                        @change="calcNumb"
                      />
                    </div>
                    <div class="col-2 mt-1 font-bold">kg</div>
                  </div>
                  <div></div>
                </div>
                <div class="row mt-2">
                  <div class="col-md-9">
                    <label
                      for="type13"
                      class="d-flex align-items-center mr-4 fs-14 txt-grey-800"
                    >
                      <el-checkbox
                        class="mr-2"
                        v-model="form.nutrition_control_ind3"
                        :true-label="1"
                        @change="totalScore"
                        :false-label="0"
                      ></el-checkbox
                      >Lượng ăn 50% nhu cầu trong 1 tuần qua
                    </label>
                  </div>
                  <div class="col-md-3 row pointer-events-none">
                    <div class="col-4">
                      <div class="font-bold">BMI:</div>
                    </div>
                    <div class="col-5">
                      <input class="mx-2 form--input" :value="getNumber()" />
                    </div>
                    <div class="col-2"></div>
                  </div>
                </div>
                <label
                  for="type14"
                  class="d-flex align-items-center mr-4 fs-14 txt-grey-800 mt-2"
                >
                  <el-checkbox
                    class="mr-2"
                    :true-label="1"
                    :false-label="0"
                    @change="totalScore"
                    v-model="form.nutrition_control_ind4"
                  ></el-checkbox
                  >Có bệnh lý nền gây kém hấp thụ hoặc hạn chế lượng ăn đường
                  tiêu hóa (Tiêu chảy, nhiễm trùng nặng, suy tim, suy hô hấp)
                </label>
                <label
                  for="type15"
                  class="d-flex align-items-center mr-4 fs-14 txt-grey-800 mt-3"
                >
                  <el-checkbox
                    class="mr-2"
                    v-model="form.nutrition_control_ind5"
                    :true-label="1"
                    :false-label="0"
                    @change="totalScore"
                  ></el-checkbox
                  >Có bệnh gây rối loạn chuyển hóa dinh dưỡng (gan thận, nội
                  tiết, chuyển hóa)
                </label>
              </div>
              <div class="mt-3 row">
                <label class="col-lg-4 fs-14 fw-700 txt-grey-900"
                  >Tổng điểm (có tính 1 điểm, không tính 0 điểm)</label
                >
                <div class="col-lg-1 col-md-3"></div>
              </div>

              <div class="mt-3">
                <div>
                  <div class="fs-14 fw-500 txt-grey-900">
                    2. KẾ HOẠCH DINH DƯỠNG
                  </div>
                </div>
                <label
                  for="type21"
                  class="d-flex align-items-center mr-4 fs-14 txt-grey-800 mt-3 pointer-events-none"
                >
                  <input
                    type="radio"
                    id="type21"
                    :checked="form.nutrition_control_option === 0"
                    class="mr-2 cursor-pointer"
                  />0 ĐIỂM: Không có nguy cơ SDD cấp, chế độ ăn bình thường,
                  đánh giá sau 1 tháng
                </label>
                <label
                  for="type22"
                  class="d-flex align-items-center mr-4 fs-14 txt-grey-800 mt-3 pointer-events-none"
                >
                  <input
                    type="radio"
                    :checked="
                      form.nutrition_control_option === 1 ||
                      form.nutrition_control_option === 2
                    "
                    id="type22"
                    class="mr-2 cursor-pointer"
                  />1-2 ĐIỂM: Nguy cơ SDD nhẹ. Hướng dẫn chế độ ăn, đánh giá lại
                  sau 2 tuần
                </label>
                <label
                  for="type23"
                  class="d-flex align-items-center mr-4 fs-14 txt-grey-800 mt-3 pointer-events-none"
                >
                  <input
                    type="radio"
                    id="type23"
                    :checked="form.nutrition_control_option === 3"
                    class="mr-2 cursor-pointer"
                  />3 ĐIỂM: Nguy cơ SDD trung bình, chuyển khám dinh dưỡng đánh
                  giá sau 1-2 tuần
                </label>
                <label
                  for="type24"
                  class="d-flex align-items-center mr-4 fs-14 txt-grey-800 mt-3 pointer-events-none"
                >
                  <input
                    type="radio"
                    id="type24"
                    :checked="
                      form.nutrition_control_option === 4 ||
                      form.nutrition_control_option === 5
                    "
                    class="mr-2 cursor-pointer"
                  />4-5 ĐIỂM: Nguy cơ SDD nặng. Hội chẩn dinh dưỡng và có chế độ
                  ăn phù hợp, đánh giá và hội chẩn lại mỗi 3-5 ngày
                </label>
              </div>
            </div>
            <div ref="viewPhieuNamVien" v-else>
              <div class="mx-auto hodo-print" id="ElementToPrint">
                <div class>
                  <div class="title">
                    <p class="mb-0 fs-17 uppercase">
                      {{
                        $globalClinic && $globalClinic.organization
                          ? $globalClinic.organization.authority_in_charge
                          : ""
                      }}
                    </p>
                    <p class="mb-0 fs-17 uppercase">
                      {{
                        $globalClinic && $globalClinic.organization
                          ? $globalClinic.organization.name
                          : ""
                      }}
                    </p>
                    <p class="mb-0 fs-17 uppercase">
                      {{ $globalClinic ? $globalClinic.name : "" }}
                    </p>
                  </div>

                  <div class="uppercase text-center font-bold fs-24 mt-2">
                    <P
                      >PHIẾU ĐÁNH GIÁ TÌNH TRẠNG DINH DƯỠNG BỆNH NHÂN NẰM
                      VIỆN</P
                    >
                  </div>
                </div>

                <div class="content-1 mt-3" v-if="data">
                  <div class="row">
                    <div class="col-7">
                      <div class="flex">
                        <p class="mb-0 fs-17">Họ tên người bệnh:&nbsp;</p>
                        <p class="uppercase font-bold fs-17">
                          {{ person.name }}
                        </p>
                      </div>
                    </div>
                    <div class="col-3">
                      <p class="mb-0 fs-17">Ngày sinh: {{ getBirthday() }}</p>
                    </div>
                    <div class="col-2">
                      <p class="mb-0 fs-17">Tuổi: {{ person.age }}</p>
                    </div>
                    <div class="col-4">
                      <p class="mb-0 fs-17">Cân nặng: {{ data.weight }}</p>
                    </div>
                    <div class="col-4">
                      <p class="mb-0 fs-17">Chiều cao: {{ data.height }}</p>
                    </div>
                    <div class="col-4">
                      <p class="mb-0 fs-17">
                        BMI: {{ bmi ? getString(bmi) : 0 }}
                      </p>
                    </div>
                    <div class="col-4">
                      <p class="mb-0 fs-17">
                        CN/tuổi: {{ ageCN ? getString(ageCN) : 0 }}
                      </p>
                    </div>
                    <div class="col-4">
                      <p class="mb-0 fs-17">
                        CC/tuổi: {{ ageCC ? getString(ageCC) : 0 }}
                      </p>
                    </div>
                    <div class="col-4">
                      <p class="mb-0 fs-17">
                        BMI/tuổi: {{ ageBMI ? getString(ageBMI) : 0 }}
                      </p>
                    </div>
                  </div>
                  <div class="table mb-1">
                    <p class="uppercase fs-17 font-bold mb-0">
                      1. Tầm soát nguy cơ dinh dưỡng:
                    </p>

                    <div class="flex w-full flex-col">
                      <div class="flex w-full">
                        <div class="p-0 col-8 border-box-table">
                          <div
                            class="text-center flex justify-center items-center tablePrint font-bold fs-18"
                          >
                            Các chỉ số cần đánh giá
                          </div>
                        </div>
                        <div
                          class="p-0 col-4 border-box-table flex items-center justify-center border-right-item-table"
                        >
                          <div
                            class="fs-18 text-center tablePrint font-bold mr-3"
                          >
                            Có
                          </div>
                        </div>
                      </div>
                      <div class="flex w-full">
                        <div class="border-box-table col-8 py-1">
                          <div
                            class="tablePrint fs-17 p-1 h-10 flex items-center"
                          >
                            Cân nặng/ tuổi hoặc BMI = -1 SD hoặc teo cơ, mất cơ,
                            mất lớp mỡ dưới da (quan sát thấy)
                          </div>
                        </div>
                        <div
                          class="border-right-item-table border-box-table col-4 relative"
                        >
                          <div class="tablePrint fs-17 text-center el-center">
                            {{ getNumb(data.nutrition_control_ind1) }}
                          </div>
                        </div>
                      </div>
                      <div class="flex w-full">
                        <div class="border-box-table col-8 py-1">
                          <div
                            class="tablePrint fs-17 p-1 h-10 flex flex-col justify-center"
                          >
                            Sụt cân hoặc không lên cân trong 3 tháng vừa qua
                          </div>
                        </div>
                        <div
                          class="border-right-item-table border-box-table col-4 relative"
                        >
                          <div class="tablePrint fs-17 text-center el-center">
                            {{ getNumb(data.nutrition_control_ind2) }}
                          </div>
                        </div>
                      </div>
                      <div class="flex w-full">
                        <div class="border-box-table col-8 py-1">
                          <div
                            class="tablePrint fs-17 p-1 h-10 flex flex-col justify-center"
                          >
                            Lượng ăn 50% nhu cầu trong 1 tuần qua
                          </div>
                        </div>
                        <div
                          class="border-right-item-table border-box-table col-4 relative"
                        >
                          <div class="tablePrint fs-17 text-center el-center">
                            {{ getNumb(data.nutrition_control_ind3) }}
                          </div>
                        </div>
                      </div>
                      <div class="flex w-full">
                        <div class="border-box-table col-8 py-1">
                          <div
                            class="tablePrint fs-17 p-1 h-10 flex flex-col justify-center"
                          >
                            Có bệnh lý nền gây kém hấp thụ hoặc hạn chế lượng ăn
                            đường tiêu hóa (Tiêu chảy, nhiễm trùng nặng, suy
                            tim, suy hô hấp)
                          </div>
                        </div>
                        <div
                          class="border-right-item-table border-box-table col-4 relative"
                        >
                          <div class="tablePrint fs-17 text-center el-center">
                            {{ getNumb(data.nutrition_control_ind4) }}
                          </div>
                        </div>
                      </div>
                      <div class="flex w-full">
                        <div class="border-box-table col-8 py-1">
                          <div
                            class="tablePrint fs-17 p-1 h-10 flex flex-col justify-center"
                          >
                            Có bệnh gây rối loạn chuyển hóa dinh dưỡng (gan
                            thận, nội tiết, chuyển hóa)
                          </div>
                        </div>
                        <div
                          class="border-right-item-table border-box-table col-4 relative"
                        >
                          <div class="tablePrint fs-17 text-center el-center">
                            {{ getNumb(data.nutrition_control_ind5) }}
                          </div>
                        </div>
                      </div>
                      <div class="flex w-full">
                        <div
                          class="border-bottom-item-table border-box-table col-8 py-1"
                        >
                          <div
                            class="tablePrint fs-17 p-1 h-10 flex flex-col justify-center"
                          >
                            Tổng điểm (có tính 1 điểm, không tính 0 điểm)
                          </div>
                        </div>
                        <div
                          class="border-right-item-table border-bottom-item-table border-box-table col-4 relative"
                        >
                          <div class="tablePrint fs-17 text-center el-center">
                            {{ total }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="content-2">
                  <p class="uppercase fs-17 font-bold mb-0">
                    2. Kế hoạch dinh dưỡng:
                  </p>
                  <div class="flex items-center">
                    <div class="h-3 w-3 box-nv mr-1 relative">
                      <i
                        class="el-icon-close absolute"
                        v-if="data.nutrition_control_option === 0"
                      ></i>
                    </div>
                    <p class="mb-0 fs-17">
                      <span class="tablePrint fs-17 font-bold">0 ĐIỂM:</span>
                      Không có nguy cơ SDD cấp, chế độ ăn bình thường, đánh giá
                      sau 1 tháng
                    </p>
                  </div>
                  <div class="flex items-center">
                    <div class="h-3 w-3 box-nv mr-1 relative">
                      <i
                        class="el-icon-close absolute"
                        v-if="
                          data.nutrition_control_option === 1 ||
                          data.nutrition_control_option === 2
                        "
                      ></i>
                    </div>
                    <p class="mb-0 fs-17">
                      <span class="tablePrint font-bold fs-17">1-2 ĐIỂM:</span>
                      Nguy cơ SDD nhẹ. Hướng dẫn chế độ ăn, đánh giá lại sau 2
                      tuần
                    </p>
                  </div>
                  <div class="flex items-center">
                    <div class="h-3 w-3 box-nv mr-1 relative">
                      <i
                        class="el-icon-close absolute"
                        v-if="data.nutrition_control_option === 3"
                      ></i>
                    </div>
                    <p class="mb-0 fs-17">
                      <span class="tablePrint font-bold fs-17">3 ĐIỂM:</span>
                      Nguy cơ SDD trung bình, chuyển khám dinh dưỡng đánh giá
                      sau 1-2 tuần
                    </p>
                  </div>
                  <div class="flex items-center">
                    <div class="h-3 w-3 box-nv relative">
                      <i
                        class="el-icon-close absolute"
                        v-if="
                          data.nutrition_control_option === 4 ||
                          data.nutrition_control_option === 5
                        "
                      ></i>
                    </div>
                    <p class="mb-0 ml-1 flex-1 fs-17">
                      <span class="tablePrint font-bold fs-17">4-5 ĐIỂM:</span>
                      Nguy cơ SDD nặng. Hội chẩn dinh dưỡng và có chế độ ăn phù
                      hợp, đánh giá và hội chẩn lại mỗi 3-5 ngày
                    </p>
                  </div>
                </div>
                <div class="block mt-10">
                  <div class="col-6"></div>
                  <div class="col-6 text-center">
                    <div class="fs-17">{{ getHMDMYVN(data.date) }}</div>
                    <!-- <div v-else class="fs-17">....., ngày ...... tháng ...... năm ......</div> -->
                    <div class="font-bold fs-18 p-0">Người thực hiện</div>
                    <div class="fs-16 italic p-0">(Ký,ghi rõ họ tên)</div>
                    <div v-show="isPrint" class="fs-17 font-bold mt-16">
                      {{ $user && $user.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalSignPDF
      containerIDProps="NutritionalInHosContainerPDF"
      elementIDProps="NutritionalInHosSignerPDF"
      imageIDProps="NutritionalInHosImageID"
      ref="NutritionalInHosSignerPDF"
      :envelopeName="envelopeName"
      @onSuccess="handleSignDocumentSuccess"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SignatureRequest from '../../../api/request/SignatureRequest'
import appUtils from '../../../utils/appUtils'
import {
  ENVELOPE_DOCUMENT_TYPE,
  ENVELOPE_NAME_TYPE
} from '../../../utils/constants'
import ModalSignPDF from '../../Signature/ModalSignPDF.vue'
import { mixinSignPdf } from '../../../utils/mixinSignPdf'

export default {
  name: 'NamVienDetail',
  components: { ModalSignPDF },
  mixins: [mixinSignPdf],
  props: ['data'],
  data () {
    return {
      is_loading: false,
      loading: false,
      total: 0,
      ageCN: 0,
      ageCC: 0,
      bmi: 0,
      ageBMI: 0,
      result: null,
      mode: '',
      form: {
        weight: null,
        height: null,
        name: 'PHIẾU ĐÁNH GIÁ DINH DƯỠNG CỦA BỆNH NHÂN NẰM VIỆN',
        nutrition_control_ind1: 0,
        nutrition_control_ind2: 0,
        nutrition_control_ind3: 0,
        nutrition_control_ind4: 0,
        nutrition_control_ind5: 0,
        nutrition_control_option: 0,
        date: window.moment().format('YYYY-MM-DD')
      },
      isPrint: false
    }
  },
  mounted () {
    let self = this
    this.$nextTick(function () {
      window.$('#modalPhieuNamVienDetail').on('hidden.bs.modal', function (e) {
        self.mode = ''
      })
    })
  },
  computed: {
    ...mapState({
      person: (state) => state.person,
      emrData: (state) => state.emrData
    }),
    envelopeName () {
      return this.handleGetEnvelopeName(ENVELOPE_NAME_TYPE.emr, this.emrData)
    },
    formatInputDateTime: {
      get () {
        return this.form.date
          ? appUtils.formatInputDateTime(this.form.date)
          : ''
      },
      set (newDate) {
        this.form.date = newDate
        return newDate
      }
    }
  },
  watch: {
    data () {
      this.total =
        this.data.nutrition_control_ind1 +
        this.data.nutrition_control_ind2 +
        this.data.nutrition_control_ind3 +
        this.data.nutrition_control_ind4 +
        this.data.nutrition_control_ind5
      this.ageCN = this.data.weight / this.person.age
      this.ageCC = this.data.height / this.person.age
      const meters = this.data.height / 100
      this.bmi = this.data.weight / (meters * meters)
      this.ageBMI = this.bmi / this.person.age
      this.form.nutrition_control_option =
        this.data.nutrition_control_ind1 +
        this.data.nutrition_control_ind2 +
        this.data.nutrition_control_ind3 +
        this.data.nutrition_control_ind4 +
        this.data.nutrition_control_ind5
      this.form.weight = this.data.weight
      this.form.height = this.data.height
      this.form.nutrition_control_ind1 = this.data.nutrition_control_ind1
      this.form.nutrition_control_ind2 = this.data.nutrition_control_ind2
      this.form.nutrition_control_ind3 = this.data.nutrition_control_ind3
      this.form.nutrition_control_ind4 = this.data.nutrition_control_ind4
      this.form.nutrition_control_ind5 = this.data.nutrition_control_ind5
      this.result = parseInt(this.data.weight) / (meters * meters)
      this.form.date = this.data.date
    }
  },
  methods: {
    async onUpdate () {
      if (!this.form.date) {
        return alert('Bạn cần chọn ngày đánh giá')
      }
      const form = {
        medical_record_id: this.$route.params.id,
        ...this.form
      }
      this.is_loading = true
      await this.$rf
        .getRequest('DoctorRequest')
        .postNutritionHospitalById(this.data.id, form)
        .then((r) => {
          this.$emit('refresh', this.data.id)
          this.$emit('refreshList')
          this.mode = ''
          this.$toast.open({
            message: 'Cập nhật thành công',
            type: 'success'
          })
        })
        .finally(() => {
          this.is_loading = false
        })
    },
    getString (value) {
      const r = Math.round(value)
      const item = r.toString().length
      if (item === 2) {
        return String(value).slice(0, 5)
      }
      if (item === 1) {
        return String(value).slice(0, 4)
      }
      if (item === 3) {
        return String(value).slice(0, 6)
      }
      if (item === 4) {
        return String(value).slice(0, 7)
      }
    },
    getNumb (value) {
      return value || 0
    },
    getNumber () {
      if (this.result) {
        const r = Math.round(this.result)
        const item = r.toString().length
        if (item === 2) {
          return String(this.result).slice(0, 5)
        }
        if (item === 1) {
          return String(this.result).slice(0, 4)
        }
        if (item === 3) {
          return String(this.result).slice(0, 6)
        }
        if (item === 4) {
          return String(this.result).slice(0, 7)
        }
      }
    },
    ModalUpload () {
      this.mode = 'update'
    },
    closeUpdate () {
      this.mode = ''
    },
    totalScore () {
      this.form.nutrition_control_option =
        this.form.nutrition_control_ind1 +
        this.form.nutrition_control_ind2 +
        this.form.nutrition_control_ind3 +
        this.form.nutrition_control_ind4 +
        this.form.nutrition_control_ind5
    },
    calcNumb () {
      const meters = parseInt(this.form.height) / 100
      this.result = parseInt(this.form.weight) / (meters * meters)
    },
    getBirthday () {
      if (!this.person.birthday) return ' '
      return window.moment(this.person.birthday).format('DD-MM-YYYY')
    },
    formatDate (date) {
      return window.moment(date).format('DD-MM-YYYY')
    },
    async onPrintNe (isOpen = true) {
      const self = this
      const bodyHTML = self.$refs.viewPhieuNamVien.innerHTML
      const tempHTML = appUtils.templateHTMLPrint({ bodyHTML })
      const base64Data = appUtils.base64.decode(tempHTML)
      const url = this.savePdfToS3(base64Data, isOpen)
      return url
    },
    async savePdfToS3 (base64, isOpen = true) {
      try {
        this.loading = true

        const payload = {
          content: base64,
          fileName: 'PhieuDanhGiaDinhDuongNamVien',
          path: 'consultations',
          type: 'test',
          type_id: 'test',
          uuid: 'test'
        }

        const res = await this.$rf
          .getRequest('DoctorRequest')
          .printDocument(payload)

        if (res?.data && isOpen) {
          window.open(res.data?.documentLink, '_blank', 'noreferrer')
        }

        this.loading = false
        return res.data?.documentLink
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    getHMDMYVN (date) {
      const dt = this.moment(date)
      // let hm = dt.clone().format('HH:mm')
      const d = dt.clone().format('DD')
      const m = dt.clone().format('MM')
      const y = dt.clone().format('YYYY')
      return 'Ngày ' + d + ' tháng ' + m + ' năm ' + y
    },
    processPrintPDF () {
      this.isPrint = true
      this.$nextTick(() => {
        this.openModalSignPDF()
      })
    },
    async openModalSignPDF () {
      this.isPrint = false

      const documentLink = await this.onPrintNe(false)

      if (!documentLink) {
        this.$toast.open({
          message: 'Không tìm thấy tài liệu cần ký',
          type: 'error'
        })
        return
      }

      const procedureResponse = await this.getSignProcedure()
      const procedureSelected = procedureResponse?.data?.[0] || {}

      const signatories = procedureSelected?.sign_nodes?.reduce((arr, node) => {
        const signs = node?.node_signatories?.map((item) => {
          let user
          if (item?.user_role === 'implementer') {
            user = {
              id: this.$user?.id,
              name: this.$user.name,
              email: this.$user.email
            }
          }

          return {
            ...item,
            user,
            user_id: user?.id
          }
        })

        return arr.concat(signs)
      }, [])

      const signerInfo = signatories?.find(
        (item) => item?.user_id === this.$user?.id
      )
      if (!signerInfo) {
        this.$toast.open({
          type: 'error',
          message: 'Bạn không có quyền ký tài liệu này'
        })

        return
      }

      this.$refs.NutritionalInHosSignerPDF.handleOpen(
        documentLink,
        ENVELOPE_DOCUMENT_TYPE.NUTRITIONAL_FORM,
        Number(this.data.id),
        signerInfo?.user_id,
        signatories,
        null,
        procedureSelected?.id
      )
    },
    async getSignProcedure () {
      try {
        const params = {
          page_num: 1,
          page_size: 1000,
          order: 'desc',
          sort_by: 'id',
          document_type: ENVELOPE_DOCUMENT_TYPE.NUTRITIONAL_FORM,
          ws_id: this.$globalClinic?.id
        }

        const request = new SignatureRequest()

        const response = await request.getSignProcedures(params)
        return response.data
      } catch (error) {
        console.log(error)
      }
    },
    async handleSignDocumentSuccess (dataBase64, responseEnvelope) {
      try {
        this.loading = true
        const file = appUtils.convertBase64tToFile(
          dataBase64,
          parseInt(Date.now() / 1000)
        )
        const url = await this.uploadFiles(file, Number(this.data.id))
        await this.handleUpdateEnvelopeDocumentURL(responseEnvelope, url)
        window.open(url, '_blank')
        // await this.handleSignature()

        window.$('#modalPhieuNamVienDetail').modal('hide')
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    async uploadFiles (file, id) {
      const params = {
        file,
        FolderTarget: `consultation`,
        IdTarget: id
      }
      const formData = new FormData()

      for (const key in params) {
        formData.append(key, params[key])
      }

      const res = await this.$rf
        .getRequest('DoctorRequest')
        .uploadFileTenant(formData)
      return res.data.fileUrl
    },
    async handleUpdateEnvelopeDocumentURL (responseEnvelope, url) {
      if (!responseEnvelope?.id) return

      try {
        const request = new SignatureRequest()

        const response = await request.updateEnvelope(responseEnvelope.id, {
          document_url: url
        })

        if (response.status === 200) {
          this.$toast.open({
            message: 'Ký thành công',
            type: 'success'
          })
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.border-box-table {
  border-top: 1px solid black;
  border-left: 1px solid black;
}
.border-bottom-item-table {
  border-bottom: 1px solid black;
}
.border-right-item-table {
  border-right: 1px solid black;
}
.tablePrint {
  font-family: "Tinos", serif;
  color: black;
}
.box-nv {
  border: 1px solid black;
}

.el-center {
  position: absolute;
  content: "";
  right: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
