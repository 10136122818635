<template>
  <div>
    <div
      class="modal fade overflow-x-hidden"
      id="modalPhieuNhapVienDetail"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalPhieuNhapVienDetailDetailLabel"
      aria-hidden="true"
      style="overflow-y: auto"
    >
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content" v-loading="loading">
          <div
            class="sticky top-0 flex justify-content-end bg-white text-base font-semibold z-50 mb-3 mt-2 print:hidden px-4"
          >
            <div class="flex items-center" v-if="mode === 'update'">
              <button
                :disabled="is_loading"
                type="button"
                class="btn border-none text-black px-3 mx-2 fs-14 fw-500"
                @click="onUpdate"
              >
                Lưu
              </button>
              <button
                v-if="!is_loading"
                type="button"
                class="close bg-white border-0"
                @click="closeUpdate"
              >
                <span class="fs-30 fw-500">&times;</span>
              </button>
            </div>
            <div class="flex items-center" v-else>
              <button
                :disabled="loading"
                class="btn border-none text-black px-3 mx-2 fs-14 fw-500"
                @click="processPrintPDF()"
              >
                <div class="loading">
                  <div v-if="loading" class="spinner-border mr-2" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill="#03267A"
                    d="M9.021 1.772a2.445 2.445 0 0 1 3.458 3.457l-.272.271l.086.086a2 2 0 0 1 0 2.828l-1.44 1.44a.5.5 0 0 1-.707-.707l1.44-1.44a1 1 0 0 0 0-1.414l-.086-.086l-5.646 5.647a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.595-.643l1.25-3.75a.5.5 0 0 1 .12-.195l6.625-6.625Zm2.75.707a1.445 1.445 0 0 0-2.042 0L3.187 9.02l-.925 2.774l2.982-.745l6.527-6.527a1.445 1.445 0 0 0 0-2.043ZM4.967 15c-1.235 0-2.208-.566-2.855-1.107l1.284-.32a3.2 3.2 0 0 0 1.57.427c.406 0 .824-.164 1.27-.457c.447-.293.878-.687 1.314-1.095l.113-.107c.385-.361.784-.737 1.166-.996c.408-.278.95-.53 1.517-.311c.376.144.62.41.791.736c.161.306.272.688.38 1.105c.05.19.133.374.23.498a.46.46 0 0 0 .114.112c.025.015.036.015.039.015c.122 0 .331-.082.63-.286c.185-.126.344-.252.502-.379c.098-.078.197-.157.302-.235c.279-.209.52-.349.697-.438a3.05 3.05 0 0 1 .278-.124l.02-.008l.007-.002l.003-.001h.001l.16.473l-.159-.474a.5.5 0 0 1 .321.947l-.004.002a2.141 2.141 0 0 0-.175.08c-.13.064-.321.174-.55.346c-.059.043-.135.104-.223.174c-.181.143-.409.324-.618.466c-.31.212-.741.46-1.192.46c-.436 0-.751-.265-.946-.517a2.435 2.435 0 0 1-.405-.858c-.11-.423-.196-.7-.296-.89c-.09-.168-.17-.232-.266-.27c-.082-.03-.245-.033-.595.206c-.313.212-.655.533-1.058.912l-.1.093c-.431.405-.92.855-1.449 1.202c-.53.348-1.14.621-1.818.621Z"
                  />
                </svg>
                {{ $t("multidisciplinary_board.btn_sign") }}
              </button>
              <button
                class="btn border-none text-black px-3 mx-2 fs-14 fw-500"
                @click="onPrintNe"
              >
                <div class="loading">
                  <div v-if="loading" class="spinner-border mr-2" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 14H17C17.2652 14 17.5196 13.8946 17.7071 13.7071C17.8946 13.5196 18 13.2652 18 13V7C18 6.73478 17.8946 6.48043 17.7071 6.29289C17.5196 6.10536 17.2652 6 17 6H3C2.73478 6 2.48043 6.10536 2.29289 6.29289C2.10536 6.48043 2 6.73478 2 7V13C2 13.2652 2.10536 13.5196 2.29289 13.7071C2.48043 13.8946 2.73478 14 3 14H4V9H16V14ZM4 4V0H16V4H17C17.7956 4 18.5587 4.31607 19.1213 4.87868C19.6839 5.44129 20 6.20435 20 7V13C20 13.7956 19.6839 14.5587 19.1213 15.1213C18.5587 15.6839 17.7956 16 17 16H16V20H4V16H3C2.20435 16 1.44129 15.6839 0.87868 15.1213C0.31607 14.5587 0 13.7956 0 13L0 7C0 6.20435 0.31607 5.44129 0.87868 4.87868C1.44129 4.31607 2.20435 4 3 4H4ZM6 18H14V11H6V18ZM6 4H14V2H6V4Z"
                    fill="#03267A"
                  />
                </svg>
                {{ $t("multidisciplinary_board.lbl_print") }}
              </button>
              <button
                class="btn border-none text-black px-3 mx-2 fs-14 fw-500"
                @click="ModalUpload"
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.75 8.99995C15.5511 8.99995 15.3603 9.07897 15.2197 9.21962C15.079 9.36028 15 9.55104 15 9.74995V14.25C15 14.4489 14.921 14.6396 14.7803 14.7803C14.6397 14.9209 14.4489 15 14.25 15H3.75C3.55109 15 3.36032 14.9209 3.21967 14.7803C3.07902 14.6396 3 14.4489 3 14.25V3.74995C3 3.55104 3.07902 3.36028 3.21967 3.21962C3.36032 3.07897 3.55109 2.99995 3.75 2.99995H8.25C8.44891 2.99995 8.63968 2.92094 8.78033 2.78028C8.92098 2.63963 9 2.44887 9 2.24995C9 2.05104 8.92098 1.86028 8.78033 1.71962C8.63968 1.57897 8.44891 1.49995 8.25 1.49995H3.75C3.15326 1.49995 2.58097 1.73701 2.15901 2.15896C1.73705 2.58092 1.5 3.15322 1.5 3.74995V14.25C1.5 14.8467 1.73705 15.419 2.15901 15.8409C2.58097 16.2629 3.15326 16.5 3.75 16.5H14.25C14.8467 16.5 15.419 16.2629 15.841 15.8409C16.2629 15.419 16.5 14.8467 16.5 14.25V9.74995C16.5 9.55104 16.421 9.36028 16.2803 9.21962C16.1397 9.07897 15.9489 8.99995 15.75 8.99995ZM4.5 9.56995V12.75C4.5 12.9489 4.57902 13.1396 4.71967 13.2803C4.86032 13.4209 5.05109 13.5 5.25 13.5H8.43C8.5287 13.5005 8.62655 13.4816 8.71793 13.4443C8.80931 13.407 8.89242 13.352 8.9625 13.2825L14.1525 8.08495L16.2825 5.99995C16.3528 5.93023 16.4086 5.84728 16.4467 5.75589C16.4847 5.66449 16.5043 5.56646 16.5043 5.46745C16.5043 5.36845 16.4847 5.27042 16.4467 5.17902C16.4086 5.08763 16.3528 5.00468 16.2825 4.93495L13.1025 1.71745C13.0328 1.64716 12.9498 1.59136 12.8584 1.55329C12.767 1.51521 12.669 1.49561 12.57 1.49561C12.471 1.49561 12.373 1.51521 12.2816 1.55329C12.1902 1.59136 12.1072 1.64716 12.0375 1.71745L9.9225 3.83995L4.7175 9.03745C4.64799 9.10753 4.593 9.19065 4.55567 9.28202C4.51835 9.3734 4.49943 9.47125 4.5 9.56995V9.56995ZM12.57 3.30745L14.6925 5.42995L13.6275 6.49495L11.505 4.37245L12.57 3.30745ZM6 9.87745L10.4475 5.42995L12.57 7.55245L8.1225 12H6V9.87745Z"
                    fill="#03267A"
                  />
                </svg>
                {{ $t("multidisciplinary_board.lbl_modify_short") }}
              </button>
              <button
                type="button"
                id="closeModal"
                class="close bg-white border-0"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" class="fs-30 fw-500">&times;</span>
              </button>
            </div>
          </div>
          <div v-if="mode === 'update'" class="text-center font-bold text-xl">
            <span>PHIẾU ĐÁNH GIÁ DINH DƯỠNG CỦA BỆNH NHÂN NHẬP VIỆN</span>
          </div>
          <div class="modal-body pt-5 pb-24">
            <div v-if="mode === 'update'">
              <div class="fw-500 mb-3 flex items-center">
                <label class="w-28">Ngày đánh giá:</label>
                <input
                  type="datetime-local"
                  class="form--input w-48"
                  v-model="formatInputDateTime"
                />
              </div>
              <div>
                <label class="fs-14 fw-400 mb-3"
                  >1. Gần đây Ông/Bà có giảm cân:</label
                >
                <div class="flex mt-2 mb-3">
                  <label
                    for="type11"
                    class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800"
                  >
                    <span class="mr-2">Không giảm cân</span>
                    <input
                      type="radio"
                      id="type11"
                      :value="0"
                      @change="checkPoint()"
                      v-model="form.is_off_weight"
                      class="mr-2 cursor-pointer"
                    />0 điểm
                  </label>
                  <label
                    for="type12"
                    class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800"
                  >
                    <span class="mr-2">Có hoặc Không chắc chắn</span>
                    <input
                      type="radio"
                      id="type12"
                      :value="2"
                      @change="checkPoint()"
                      v-model="form.is_off_weight"
                      class="mr-2 cursor-pointer"
                    />2 điểm
                  </label>
                </div>

                <div v-if="this.form.is_off_weight === 2">
                  <label class="fs-14 fw-400 mb-3"
                    >Nếu có, giảm bao nhiêu cân</label
                  >
                  <div class="flex mt-2 mb-3">
                    <label
                      for="type11"
                      class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800"
                    >
                      <span class="mr-2">1-5 kg</span>
                      <input
                        type="radio"
                        id="type11"
                        :value="0"
                        v-model="form.weight_loss"
                        class="mr-2 cursor-pointer"
                      />0 điểm
                    </label>
                    <label
                      for="type12"
                      class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800"
                    >
                      <span class="mr-2">≥ 6kg/không chắc chắn</span>
                      <input
                        type="radio"
                        id="type12"
                        :value="2"
                        v-model="form.weight_loss"
                        class="mr-2 cursor-pointer"
                      />2 điểm
                    </label>
                  </div>
                </div>

                <label class="fs-14 fw-400 mb-3"
                  >2. Ông/Bà có ăn uống kém do giảm ngon miệng:</label
                >
                <div class="flex mt-2 mb-3">
                  <label
                    for="type11"
                    class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800"
                  >
                    <span class="mr-2">Không</span>
                    <input
                      type="radio"
                      id="type11"
                      :value="0"
                      @change="checkPoint()"
                      v-model="form.anorexia"
                      class="mr-2 cursor-pointer"
                    />0 điểm
                  </label>
                  <label
                    for="type12"
                    class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800"
                  >
                    <span class="mr-2">Có</span>
                    <input
                      type="radio"
                      id="type12"
                      :value="2"
                      v-model="form.anorexia"
                      @change="checkPoint()"
                      class="mr-2 cursor-pointer"
                    />2 điểm
                  </label>
                </div>

                <div class="mt-2 d-flex mb-4">
                  <label class="fs-14 fw-700 mb-3 mr-4">Phân loại</label>
                  <div>
                    <label
                      for="type11"
                      class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800 mb-3"
                    >
                      <input
                        type="radio"
                        id="type11"
                        :checked="result === 0"
                        name="drone"
                        class="mr-2 pointer-events-none"
                      />Không nguy cơ (tổng điểm MST = 0 hoặc MST = 1)
                    </label>

                    <label
                      for="type12"
                      class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800"
                    >
                      <input
                        name="drone"
                        type="radio"
                        :checked="result >= 2"
                        id="type12"
                        class="mr-2 pointer-events-none"
                      />Có nguy cơ (tổng điểm MST >= 2 --> đánh giá tiếp bảng
                      dưới)
                    </label>
                  </div>
                </div>

                <div>
                  <p class="fs-14 fw-400 fst-italic">
                    (Lưu ý: BN chấn thương nặng, nằm ICU, người cao tuổi > 65
                    tuổi mắc bệnh đều tính là đối tượng có nguy cơ phải đánh giá
                    SGA)
                  </p>
                  <p class="fs-14 fw-700 text-black">
                    Đánh giá tổng thể tình trạng dinh dưỡng theo chủ quan
                    (SGA-Subjective Global Assessment)
                  </p>

                  <label class="fs-14 fw-400 mb-3"
                    >1. Sụt cân trong 3-6 tháng trước khi nhập viện:</label
                  >
                  <div class="mt-2 mb-3">
                    <label
                      for="type14"
                      class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800 mb-3"
                    >
                      <span class="mr-2">Không</span>
                      <input
                        type="radio"
                        v-model="noCheck"
                        :value="1"
                        id="type14"
                        @change="onchange1"
                        name="type14"
                        class="mr-2 cursor-pointer"
                      />
                    </label>
                    <label
                      for="type14"
                      class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800"
                    >
                      <span class="mr-15">Có</span>
                      <input
                        type="radio"
                        v-model="check"
                        @change="onchange2"
                        :value="1"
                        name="type14"
                        id="type14"
                        class="mr-2 cursor-pointer"
                      />
                      <div v-if="check" class="ml-4">
                        <input
                          class="form--input w-20 mr-2"
                          v-model="form.weight_state_kg"
                          type="text"
                        />kg /
                        <input
                          v-model="form.weight_state_month"
                          class="form--input w-20 mx-2"
                          type="text"
                        />Tháng
                        <input
                          v-model="form.weight_state_guyg"
                          class="form--input w-20 mx-2"
                          type="text"
                        />%
                      </div>
                    </label>
                  </div>

                  <div class="mb-3">
                    <label class="fs-14 fw-400 mb-3"
                      >2. Ăn uống trong 2 tuần trước khi vào viện:</label
                    >
                    <div class="row mt-2 mb-3">
                      <div>
                        <label
                          for="type11"
                          class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800 mb-3"
                        >
                          <span class="mr-2">Bình thường</span>
                          <input
                            type="radio"
                            id="type11"
                            :value="1"
                            name="type11"
                            v-model="form.eating_state"
                            @change="checkEating1"
                            class="mr-2 cursor-pointer"
                          />
                        </label>
                      </div>
                      <div class="col-6">
                        <label
                          for="type11"
                          class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800 mb-3"
                        >
                          <span class="mr-2">Có giảm sút</span>
                          <input
                            type="radio"
                            id="type11"
                            :value="1"
                            name="type11"
                            v-model="checkEatingDown"
                            class="mr-2 cursor-pointer"
                          />
                        </label>
                      </div>
                      <div
                        class="col-6"
                        v-if="checkEating === 1 || checkEatingDown === 1"
                      >
                        <div class="mb-3">
                          <label
                            for="type19"
                            class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800"
                          >
                            <span class="mr-2"
                              >Trên 50% so với bình thường</span
                            >
                            <input
                              type="radio"
                              id="type19"
                              :value="2"
                              v-model="form.eating_state"
                              class="mr-2 cursor-pointer"
                            />
                          </label>
                        </div>
                        <div>
                          <label
                            for="type19"
                            class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800"
                          >
                            <span class="mr-2"
                              >Dưới 50% so với bình thường</span
                            >
                            <input
                              type="radio"
                              id="type19"
                              :value="3"
                              v-model="form.eating_state"
                              class="mr-2 cursor-pointer"
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <label class="fs-14 fw-400 mb-3"
                    >3. Khám (0 = không; 1 = nhẹ; 2 = vừa; 3 = nặng):</label
                  >
                  <div class="mt-2 mb-3 row">
                    <div class="col">
                      <div class="mb-3">
                        Lớp mỡ dưới da vùng cơ mặt; cơ nhị đầu hay cơ tam đầu
                      </div>
                      <div>Cơ vùng thái dương; delta; cơ ngực; tứ đầu đùi</div>
                    </div>
                    <div class="col">
                      <div class="mb-3">
                        <el-select
                          class="w-20 mr-2"
                          v-model="form.examination_result_01"
                        >
                          <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          ></el-option> </el-select
                        >Teo lớp mỡ dưới da
                      </div>
                      <div class="mb-3">
                        <el-select
                          class="w-20 mr-2"
                          v-model="form.examination_result_02"
                        >
                          <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          ></el-option> </el-select
                        >Teo cơ
                      </div>
                      <div class="mb-3">
                        <el-select
                          class="w-20 mr-2"
                          v-model="form.examination_result_03"
                        >
                          <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          ></el-option> </el-select
                        >Phù chi/cột sống thắt lưng
                      </div>
                      <div class="mb-3">
                        <el-select
                          class="w-20 mr-2"
                          v-model="form.examination_result_04"
                        >
                          <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          ></el-option> </el-select
                        >Báng bụng
                      </div>
                    </div>
                  </div>

                  <label class="fs-14 fw-400 mb-3">4. Phân loại SGA</label>
                  <div>
                    <p class="fst-italic fs-14 fw-400">Cách phân loại</p>
                    <label
                      for="type15"
                      class="d-flex align-items-center mr-4 fs-14 fw-400 mb-3"
                    >
                      <input
                        type="radio"
                        id="type15"
                        :value="1"
                        v-model="form.sga"
                        class="mr-2 cursor-pointer"
                      />SGA – A: Dinh dưỡng tốt
                    </label>
                    <label
                      for="type15"
                      class="d-flex align-items-center mr-4 fs-14 fw-400 mb-3"
                    >
                      <input
                        type="radio"
                        id="type15"
                        :value="2"
                        v-model="form.sga"
                        class="mr-2 cursor-pointer"
                      />SGA – B: Nghi ngờ SĐ hay SĐ vừa
                    </label>
                    <label
                      for="type15"
                      class="d-flex align-items-center mr-4 fs-14 fw-400 mb-3"
                    >
                      <input
                        type="radio"
                        id="type15"
                        :value="3"
                        v-model="form.sga"
                        class="mr-2 cursor-pointer"
                      />SGA – C: SĐ nặng
                    </label>
                  </div>

                  <label class="fs-14 fw-400 mb-3"
                    >5. Xác định nhu cầu dinh dưỡng (năng lượng và đạm)</label
                  >
                  <div class="mt-2 mb-3">
                    <label
                      for="type11"
                      class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800 mb-3"
                    >
                      <input
                        v-model="other1"
                        @change="inputOther1"
                        :value="1"
                        type="radio"
                        id="type11"
                        name="type11"
                        class="mr-2 cursor-pointer"
                      />1,45-1,50m
                      <span class="ml-5 mr-5">47-49kg/1500-1600kcal</span>55-58g
                      đạm
                    </label>
                    <label
                      for="type11"
                      class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800 mb-3"
                    >
                      <input
                        type="radio"
                        id="type11"
                        name="type11"
                        v-model="other2"
                        @change="inputOther2"
                        :value="1"
                        class="mr-2 cursor-pointer"
                      />1,51-1,55m
                      <span class="ml-5 mr-5">50-52kg/1600-1700kcal</span>59-62g
                      đạm
                    </label>
                    <label
                      for="type11"
                      class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800 mb-3"
                    >
                      <input
                        type="radio"
                        id="type11"
                        v-model="other3"
                        name="type11"
                        @change="inputOther3"
                        :value="1"
                        class="mr-2 cursor-pointer"
                      />1,56-1,60m
                      <span class="ml-5 mr-5">54-56kg/1700-1800kcal</span>63-66g
                      đạm
                    </label>
                    <label
                      for="type11"
                      class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800 mb-3"
                    >
                      <input
                        type="radio"
                        id="type11"
                        :value="1"
                        class="mr-2 cursor-pointer"
                        name="type11"
                        v-model="other4"
                        @change="inputOther4"
                      />1,61-1,65m
                      <span class="ml-5 mr-5">57-59kg/1800-1900kcal</span>67-70g
                      đạm
                    </label>
                    <label
                      for="type11"
                      class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800 mb-3"
                    >
                      <input
                        type="radio"
                        id="type11"
                        :value="1"
                        class="mr-2 cursor-pointer"
                        name="type11"
                        v-model="other5"
                        @change="inputOther5"
                      />1,61-1,65m
                      <span class="ml-5 mr-5">60-62kg/1900-2000kcal</span>71-74g
                      đạm
                    </label>
                    <label
                      for="type11"
                      class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800 mb-3"
                    >
                      <input
                        type="radio"
                        id="type11"
                        :value="1"
                        class="mr-2 cursor-pointer"
                        name="type11"
                        v-model="other6"
                        @change="inputOther6"
                      />1,71-1,75m
                      <span class="ml-5 mr-5">63-65kg/2000-2100kcal</span>75-78g
                      đạm
                    </label>
                  </div>
                  <div class="mt-3">
                    <label
                      for="type12"
                      class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800 mb-3"
                    >
                      <input
                        :value="1"
                        v-model="other"
                        class="form--input w-20 mr-2 cursor-pointer"
                        type="radio"
                        @change="clearOther"
                      />
                      <span class="mr-2">Hoặc nhu cầu khác:</span>
                      <div v-if="other">
                        <input
                          class="form--input w-20 mr-2"
                          type="text"
                          v-model="form.nutritional_kcal"
                        />kcal;
                        <input
                          class="form--input w-20 mx-2"
                          type="text"
                          v-model="form.nutritional_protein"
                        />g đạm;
                        <input
                          v-model="form.nutritional_sugar"
                          class="form--input w-20 mx-2"
                        />g đường;
                        <input
                          class="form--input w-20 mx-2"
                          v-model="form.nutritional_fat"
                        />g béo;
                      </div>
                    </label>
                  </div>

                  <div class="mb-3">
                    <label class="fs-14 fw-400 mb-3"
                      >6. Kế hoạch dinh dưỡng cho người bệnh</label
                    >
                    <div class="row mt-2 mb-3">
                      <div class="col">
                        <label
                          for="type11"
                          class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800 mb-3"
                        >
                          <el-checkbox
                            class="mr-2"
                            v-model="form.nutritional_option_01"
                            :true-label="1"
                            :false-label="0"
                          />
                          <span class="mr-2">Chỉ định mã chế độ ăn</span>
                        </label>
                        <label
                          for="type12"
                          class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800"
                        >
                          <el-checkbox
                            class="mr-2"
                            :true-label="1"
                            :false-label="0"
                            v-model="form.nutritional_option_03"
                          />
                          <span class="mr-2">Bổ sung thức uống DD</span>
                        </label>
                      </div>
                      <div class="col">
                        <label
                          for="type11"
                          class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800 mb-3"
                        >
                          <el-checkbox
                            class="mr-2"
                            :true-label="1"
                            :false-label="0"
                            v-model="form.nutritional_option_02"
                          />
                          <span class="mr-2">Qua ống thông</span>
                        </label>
                        <label
                          for="type12"
                          class="d-flex align-items-center mr-4 fs-14 fw-400 txt-grey-800"
                        >
                          <el-checkbox
                            class="mr-2"
                            :true-label="1"
                            :false-label="0"
                            v-model="form.nutritional_option_04"
                          />
                          <span class="mr-2">Qua tĩnh mạch</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="mx-auto"
              ref="viewPhieuNhapVien"
              id="ElementToPrint"
            >
              <div class="hodo-print">
                <div class="p-0 title">
                  <p class="mb-0 fs-17 uppercase">
                    {{
                      $globalClinic && $globalClinic.organization
                        ? $globalClinic.organization.authority_in_charge
                        : ""
                    }}
                  </p>
                  <p class="mb-0 fs-17 uppercase">
                    {{
                      $globalClinic && $globalClinic.organization
                        ? $globalClinic.organization.name
                        : ""
                    }}
                  </p>
                  <p class="mb-0 fs-17 uppercase">
                    {{ $globalClinic ? $globalClinic.name : "" }}
                  </p>
                </div>

                <div class="p-0 uppercase text-center font-bold fs-24 mt-2">
                  <P>ĐÁNH GIÁ DINH DƯỠNG NGƯỜI BỆNH NHẬP VIỆN</P>
                </div>

                <div class="content-1 mt-3" v-if="data">
                  <div class>
                    <div class="flex items-center">
                      <div class="w-fit">
                        <p class="mb-0 fs-17">
                          Họ tên người bệnh:
                          <span class="uppercase font-bold fs-17">{{
                            person.name
                          }}</span>
                        </p>
                      </div>
                      <div class="col-3 ml-24">
                        <p class="mb-0 fs-17">Ngày sinh: {{ getBirthday() }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4">
                      <p class="mb-0 fs-17">Cân nặng lúc vào viện:</p>
                    </div>
                    <div class="col-4">
                      <p class="mb-0 fs-17">Chiều cao:</p>
                    </div>
                    <div class="col-4">
                      <p class="mb-0 fs-17">BMI:</p>
                    </div>
                  </div>
                  <div class>
                    <p class="mb-0 fs-17">
                      Chẩn đoán:
                      {{
                        medicalRecordMain
                          ? medicalRecordMain.treat_dept_main_name
                          : ""
                      }}
                    </p>
                  </div>

                  <div class="table mb-1">
                    <div class>
                      <p class="fs-17 font-bold mb-0">
                        Sàng lọc nguy cơ SSD (MST-ferguson et al, 1999):
                      </p>
                    </div>
                    <div>
                      <div class>
                        <p class="mb-0 fs-17">1. Gần đây Ông/Bà có giảm cân:</p>
                      </div>
                      <div class="flex p-0">
                        <div
                          class="flex-1 flex justify-center items-center p-0"
                        >
                          <div class="col-4">
                            <div class="p-0 tablePrint text-right fs-17">
                              Không giảm
                            </div>
                          </div>
                          <div class="col-1 p-0">
                            <div
                              class="box-nv mt-0 h-3 w-3 ml-3 mr-1 p-0 relative"
                            >
                              <i
                                v-if="data.is_off_weight === 0"
                                class="el-icon-close el-center"
                              ></i>
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="ml-2.5 p-0 tablePrint fs-17">
                              0 điểm
                            </div>
                          </div>
                        </div>
                        <div class="flex-1 flex p-0 items-center">
                          <div class="col-6">
                            <div class="text-right tablePrint p-0 fs-17">
                              Có hoặc Không chắc chắn
                            </div>
                          </div>
                          <div class="col-1 mr-2 p-0">
                            <div
                              class="box-nv mt-0 h-3 w-3 ml-3 mr-1 p-0 relative"
                            >
                              <i
                                v-if="data.is_off_weight === 2"
                                class="el-icon-close el-center"
                              ></i>
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="p-0 tablePrint fs-17">2 điểm</div>
                          </div>
                        </div>
                      </div>
                      <div class>
                        <p class="mb-0 fs-13 ml-3 fs-17">
                          Nếu có, giảm bao nhiêu cân:
                        </p>
                      </div>

                      <div class="flex p-0">
                        <div
                          class="flex-1 flex justify-center items-center p-0 pl-1"
                        >
                          <div class="col-4">
                            <div class="p-0 tablePrint text-right fs-17">
                              1-5 kg
                            </div>
                          </div>
                          <div class="col-1 mr-1 p-0">
                            <div
                              class="box-nv mt-0 h-3 w-3 ml-3 mr-1 p-0 relative"
                            >
                              <i
                                v-if="data.weight_loss === 0"
                                class="el-icon-close el-center"
                              ></i>
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="ml-2 p-0 tablePrint fs-17">0 điểm</div>
                          </div>
                        </div>
                        <div class="flex-1 flex p-0">
                          <div class="col-6">
                            <div class="text-right tablePrint p-0 fs-17">
                              ≥ 6kg/không chắc chắn
                            </div>
                          </div>
                          <div class="col-1 mr-2 p-0">
                            <div
                              class="box-nv mt-0 h-3 w-3 ml-3 mr-1 p-0 relative"
                            >
                              <i
                                v-if="data.weight_loss === 2"
                                class="el-icon-close el-center"
                              ></i>
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="p-0 tablePrint fs-17">2 điểm</div>
                          </div>
                        </div>
                      </div>
                      <div class>
                        <p class="mb-0 fs-17">
                          2. Ông/Bà có ăn uống kém do giảm ngon miệng:
                        </p>
                      </div>
                      <div class="flex p-0">
                        <div
                          class="flex-1 flex justify-center items-center p-0 pl-1"
                        >
                          <div class="col-4">
                            <div class="p-0 tablePrint text-right fs-17">
                              Không
                            </div>
                          </div>
                          <div class="col-1 p-0">
                            <div
                              class="box-nv mt-0 h-3 w-3 ml-3 mr-1 p-0 relative"
                            >
                              <i
                                v-if="data.anorexia === '0'"
                                class="el-icon-close el-center"
                              ></i>
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="ml-2 p-0 tablePrint fs-17">0 điểm</div>
                          </div>
                        </div>
                        <div class="flex-1 p-0 flex items-center">
                          <div class="col-6">
                            <div class="text-right tablePrint p-0 fs-17">
                              Có
                            </div>
                          </div>
                          <div class="col-1 mr-2 p-0">
                            <div
                              class="box-nv mt-0 h-3 w-3 ml-3 mr-1 p-0 relative"
                            >
                              <i
                                v-if="data.anorexia === '2'"
                                class="el-icon-close el-center"
                              ></i>
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="p-0 tablePrint fs-17">2 điểm</div>
                          </div>
                        </div>
                      </div>
                      <div class="flex my-1">
                        <div class="tablePrint fs-17 font-bold p-0 mr-3">
                          Phân loại:
                        </div>
                        <div class="p-0">
                          <div class="flex tablePrint items-center">
                            <div class="box-nv mt-0 h-3 w-3 mr-1 p-0">
                              <i
                                v-if="result === 0"
                                class="el-icon-close el-center"
                              ></i>
                            </div>
                            <div class="fs-17">
                              Không nguy cơ (tổng điểm MST = 0 hoặc MST = 1)
                            </div>
                          </div>
                          <div class="flex tablePrint items-center">
                            <div class="box-nv mt-0 h-3 w-3 mr-1 p-0">
                              <i
                                v-if="result >= 2"
                                class="el-icon-close el-center"
                              ></i>
                            </div>

                            <div class="fs-17">
                              Có nguy cơ (tổng điểm MST >= 2 --> đánh giá tiếp
                              bảng dưới)
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class>
                        <p class="fs-17 italic">
                          (Lưu ý: BN chấn thương nặng, nằm ICU, người cao tuổi >
                          65 tuổi mắc bệnh đều tính là đối tượng có nguy cơ phải
                          đánh giá SGA)
                        </p>
                      </div>
                    </div>

                    <div class="table">
                      <div class>
                        <div
                          class="tablePrint border-box fs-18 text-center font-bold"
                        >
                          Đánh giá tổng thể tình trạng dinh dưỡng theo chủ quan
                          (SGA-Subjective Global Assessment)
                        </div>
                      </div>
                      <div class="border-l border-r border-b">
                        <div class="p-0">
                          <div class="p-0 ml-1 tablePrint fs-17 font-bold">
                            1. Sụt cân trong 3-6 tháng trước khi nhập viện:
                          </div>
                        </div>
                        <div class="flex p-0">
                          <div
                            class="flex-1 flex items-center justify-center p-0"
                          >
                            <div class="col-5 pl-4">
                              <div class="p-0 tablePrint text-right fs-17">
                                Không
                              </div>
                            </div>
                            <div class="col-1 p-0">
                              <div
                                class="box-nv h-3 w-3 ml-3 mr-1 p-0 relative"
                              >
                                <i
                                  v-if="
                                    !data.weight_state_kg &&
                                    !data.weight_state_guyg &&
                                    !data.weight_state_month
                                  "
                                  class="el-icon-close absoel-centerlute"
                                ></i>
                              </div>
                            </div>
                            <div class="col-2 p-0 tablePrint fs-17"></div>
                          </div>
                          <div class="col-7 flex p-0"></div>
                        </div>
                        <div class="flex p-0">
                          <div
                            class="flex-1 flex items-center justify-center p-0"
                          >
                            <div class="col-5 pl-4">
                              <div class="p-0 tablePrint text-right fs-17">
                                Có
                              </div>
                            </div>
                            <div class="col-1 p-0">
                              <div
                                class="box-nv h-3 w-3 ml-3 mr-1 p-0 relative"
                              >
                                <i
                                  v-if="
                                    data.weight_state_kg ||
                                    data.weight_state_month ||
                                    data.weight_state_guyg
                                  "
                                  class="el-icon-close el-center"
                                ></i>
                              </div>
                            </div>
                            <div class="col-2 p-0 tablePrint fs-17"></div>
                          </div>
                          <div class="col-7 flex p-0">
                            <div class="col-3">
                              <div class="tablePrint fs-17 p-0">
                                {{ data.weight_state_kg }} kg
                              </div>
                            </div>
                            <div class="col-3">
                              <div class="tablePrint fs-17 p-0">
                                {{ data.weight_state_month }} Tháng
                              </div>
                            </div>
                            <div class="col-3">
                              <div class="tablePrint fs-17 p-0">
                                {{ data.weight_state_guyg }} %
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="p-0">
                          <div class="p-0 ml-1 tablePrint fs-17 font-bold">
                            2. Ăn uống trong 2 tuần trước khi vào viện:
                          </div>
                        </div>
                        <div class="flex p-0">
                          <div
                            class="flex-1 flex items-center justify-center p-0"
                          >
                            <div class="col-5 pl-4">
                              <div class="p-0 tablePrint text-right fs-17">
                                Bình thường
                              </div>
                            </div>
                            <div class="col-1 p-0">
                              <div
                                class="box-nv h-3 w-3 ml-3 mr-1 p-0 relative"
                              >
                                <i
                                  v-if="data.eating_state === 1"
                                  class="el-icon-close el-center"
                                ></i>
                              </div>
                            </div>
                            <div class="col-2 p-0 tablePrint fs-17"></div>
                          </div>
                          <div class="col-7 flex p-0"></div>
                        </div>

                        <div class="flex p-0">
                          <div
                            class="flex-1 flex items-center justify-center p-0"
                          >
                            <div class="col-5 pl-4">
                              <div class="p-0 tablePrint text-right fs-17">
                                Có giảm sút
                              </div>
                            </div>
                            <div class="col-1 p-0">
                              <div
                                class="box-nv h-3 w-3 ml-3 mr-1 p-0 relative"
                              >
                                <i
                                  v-if="
                                    data.eating_state === 2 ||
                                    data.eating_state === 3
                                  "
                                  class="el-icon-close el-center"
                                ></i>
                              </div>
                            </div>
                            <div class="col-2 p-0 tablePrint fs-17"></div>
                          </div>
                          <div class="col-7 p-0">
                            <div class="flex">
                              <div class="col-8">
                                <div class="text-right tablePrint p-0 fs-17">
                                  Trên 50% so với bình thường
                                </div>
                              </div>
                              <div class="col-1 p-0 mt-1">
                                <div
                                  class="box-nv h-3 w-3 ml-3 mr-1 p-0 relative"
                                >
                                  <i
                                    v-if="data.eating_state === 2"
                                    class="el-icon-close el-center"
                                  ></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="flex p-0">
                          <div class="flex-1 flex justify-center p-0"></div>
                          <div class="col-7 p-0">
                            <div class="flex">
                              <div class="col-8">
                                <div class="text-right tablePrint p-0 fs-17">
                                  Dưới 50% so với bình thường
                                </div>
                              </div>
                              <div class="col-1 p-0 mt-1">
                                <div
                                  class="box-nv h-3 w-3 ml-3 mr-1 p-0 relative"
                                >
                                  <i
                                    v-if="data.eating_state === 3"
                                    class="el-icon-close el-center"
                                  ></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="border-l border-r border-b">
                        <div class="p-0">
                          <div class="p-0 ml-1 tablePrint fs-17 font-bold">
                            3. Khám (0 = không; 1 = nhẹ; 2 = vừa; 3 = nặng):
                          </div>
                        </div>

                        <div class="flex p-0">
                          <div class="col-7 p-0">
                            <div class="flex p-0 pl-1">
                              <div
                                class="p-0 tablePrint whitespace-nowrap text-right fs-17"
                              >
                                Lớp mỡ dưới da vùng cơ mặt; cơ nhị đầu hay cơ
                                tam đầu
                              </div>
                            </div>
                          </div>
                          <div class="col-4 p-0">
                            <div class="flex items-center">
                              <div class="col-8">
                                <div class="text-right tablePrint p-0 fs-17">
                                  Teo lớp mỡ dưới da
                                </div>
                              </div>
                              <div class="col-1 p-0">
                                <div
                                  class="box-nv h-3 w-3 ml-3 mr-1 p-0 flex items-center justify-center overflow-hidden"
                                >
                                  <p class="p-0 text-center fs-14">
                                    {{ data.examination_result_01 }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="flex p-0">
                          <div class="col-7 p-0">
                            <div class="flex p-0 pl-1">
                              <div
                                class="p-0 tablePrint whitespace-nowrap text-right fs-17"
                              >
                                Cơ vùng thái dương; delta; cơ ngực; tứ đầu đùi
                              </div>
                            </div>
                          </div>
                          <div class="col-4 p-0">
                            <div class="flex items-center">
                              <div class="col-8">
                                <div class="text-right tablePrint p-0 fs-17">
                                  Teo cơ
                                </div>
                              </div>
                              <div class="col-1 p-0">
                                <div
                                  class="box-nv h-3 w-3 ml-3 mr-1 p-0 flex items-center justify-center overflow-hidden"
                                >
                                  <p class="p-0 text-center fs-14">
                                    {{ data.examination_result_02 }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="flex p-0">
                          <div class="col-7 p-0"></div>
                          <div class="col-4 p-0">
                            <div class="flex items-center">
                              <div class="col-8">
                                <div class="text-right tablePrint p-0 fs-17">
                                  Phù chi/cột sống thắt lưng
                                </div>
                              </div>
                              <div class="col-1 p-0">
                                <div
                                  class="box-nv h-3 w-3 ml-3 mr-1 p-0 flex items-center justify-center overflow-hidden"
                                >
                                  <p class="p-0 text-center fs-14">
                                    {{ data.examination_result_03 }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="flex p-0">
                          <div class="col-7 p-0"></div>
                          <div class="col-4 p-0">
                            <div class="flex items-center">
                              <div class="col-8">
                                <div class="text-right tablePrint p-0 fs-17">
                                  Báng bụng
                                </div>
                              </div>
                              <div class="col-1 p-0">
                                <div
                                  class="box-nv h-3 w-3 ml-3 mr-1 p-0 flex items-center justify-center overflow-hidden"
                                >
                                  <p class="p-0 text-center fs-14">
                                    {{ data.examination_result_04 }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="flex justify-center p-0">
                        <div class="flex-1 p-0"></div>
                        <div class="flex items-center py-0">
                          <div class="text-right tablePrint p-0 fs-17">Báng bụng</div>
                          <div class="box-nv  h-3 w-3 ml-4 p-0">
                            <p class="p-0 ml-1">{{ data.examination_result_04 }}</p>
                          </div>
                        </div>
                      </div>-->
                      </div>
                      <div class="border-l border-r border-b">
                        <div class="p-0">
                          <div class="p-0 ml-1 tablePrint fs-17 font-bold">
                            4. Phân loại SGA
                          </div>
                        </div>
                        <div class="flex p-0">
                          <div
                            class="flex-1 flex items-center justify-center p-0"
                          >
                            <div class="col-5 pl-4">
                              <div class="p-0 tablePrint text-right fs-17">
                                SGA – A
                              </div>
                            </div>
                            <div class="col-1 p-0">
                              <div
                                class="box-nv h-3 w-3 ml-3 mr-1 p-0 relative"
                              >
                                <i
                                  v-if="data.sga === 1"
                                  class="el-icon-close el-center"
                                ></i>
                              </div>
                            </div>
                            <div class="col-2 p-0 tablePrint fs-17"></div>
                          </div>
                          <div class="col-7 p-0">
                            <div class="flex">
                              <div class="col-8">
                                <div class="text-left tablePrint p-0 fs-17">
                                  Dinh dưỡng tốt
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="flex p-0">
                          <div
                            class="flex-1 flex items-center justify-center p-0"
                          >
                            <div class="col-5 pl-4">
                              <div class="p-0 tablePrint text-right fs-17">
                                SGA – B
                              </div>
                            </div>
                            <div class="col-1 p-0">
                              <div
                                class="box-nv h-3 w-3 ml-3 mr-1 p-0 relative"
                              >
                                <i
                                  v-if="data.sga === 2"
                                  class="el-icon-close el-center"
                                ></i>
                              </div>
                            </div>
                            <div class="col-2 p-0 tablePrint fs-17"></div>
                          </div>
                          <div class="col-7 p-0">
                            <div class="flex">
                              <div class="col-8">
                                <div class="text-left tablePrint p-0 fs-17">
                                  Nghi ngờ SĐ hay SĐ vừa
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="flex p-0">
                          <div
                            class="flex-1 flex items-center justify-center p-0"
                          >
                            <div class="col-5 pl-4">
                              <div class="p-0 tablePrint text-right fs-17">
                                SGA – C
                              </div>
                            </div>
                            <div class="col-1 p-0">
                              <div
                                class="box-nv h-3 w-3 ml-3 mr-1 p-0 relative"
                              >
                                <i
                                  v-if="data.sga === 3"
                                  class="el-icon-close el-center"
                                ></i>
                              </div>
                            </div>
                            <div class="col-2 p-0 tablePrint fs-17"></div>
                          </div>
                          <div class="col-7 p-0">
                            <div class="flex">
                              <div class="col-8">
                                <div class="text-left tablePrint p-0 fs-17">
                                  SĐ nặng
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="p-0">
                          <div class="tablePrint p-0 ml-1 fs-17">
                            Cách phân loại:
                          </div>
                          <div class="tablePrint p-0 ml-1 fs-17">
                            SGA - A: có sụt cân &lt; 5%, ăn uống và khám bình
                            thường
                          </div>
                          <div class="tablePrint p-0 ml-1 fs-17">
                            SGA - B: sụt 5-10% CN, ăn &gt; 50%, teo mỡ và cơ mức
                            độ nhẹ hay vừa
                          </div>
                          <div class="tablePrint p-0 ml-1 fs-17">
                            SGA - C: sụt ≥ 10%, ăn &lt; 50%, teo mỡ và cơ năng
                            hay có phù chi, báng bụng (trừ bệnh gan, thận)
                          </div>
                        </div>
                      </div>
                      <div class="border-l border-r border-b">
                        <div class="p-0">
                          <div class="p-0 ml-1 tablePrint fs-17 font-bold">
                            5. Xác định nhu cầu dinh dưỡng (năng lượng và đạm)
                          </div>
                        </div>
                        <div class="row p-0">
                          <div class="p-0 col-4">
                            <div class="tablePrint p-0 ml-3 flex items-center">
                              <div
                                class="box-nv mt-0 h-3 w-3 ml-3 mr-1 p-0 relative"
                              >
                                <i
                                  v-if="
                                    data.nutritional_kcal === '1500-1600' &&
                                    data.nutritional_protein === '55-58'
                                  "
                                  class="el-icon-close el-center"
                                ></i>
                              </div>
                              <div class="fs-17">1,45-1,50m</div>
                            </div>
                          </div>
                          <div class="p-0 col-3">
                            <div class="tablePrint p-0 fs-17">
                              47-49kg/1500-1600kcal
                            </div>
                          </div>
                          <div class="p-0 col-2">
                            <div class="tablePrint p-0 ml-3 fs-17">
                              55-58g đạm
                            </div>
                          </div>
                        </div>
                        <div class="row p-0">
                          <div class="p-0 col-4">
                            <div class="tablePrint p-0 ml-3 flex items-center">
                              <div
                                class="box-nv mt-0 h-3 w-3 ml-3 mr-1 p-0 relative"
                              >
                                <i
                                  v-if="
                                    data.nutritional_kcal === '1600-1700' &&
                                    data.nutritional_protein === '59-62'
                                  "
                                  class="el-icon-close el-center"
                                ></i>
                              </div>
                              <div class="fs-17">1,51-1,55m</div>
                            </div>
                          </div>
                          <div class="p-0 col-3">
                            <div class="tablePrint p-0 fs-17">
                              50-52kg/1600-1700kcal
                            </div>
                          </div>
                          <div class="p-0 col-2">
                            <div class="tablePrint p-0 ml-3 fs-17">
                              59-62g đạm
                            </div>
                          </div>
                        </div>

                        <div class="row p-0">
                          <div class="p-0 col-4">
                            <div class="tablePrint p-0 ml-3 flex items-center">
                              <div
                                class="box-nv mt-0 h-3 w-3 ml-3 mr-1 p-0 relative"
                              >
                                <i
                                  v-if="
                                    data.nutritional_kcal === '1700-1800' &&
                                    data.nutritional_protein === '63-66'
                                  "
                                  class="el-icon-close el-center"
                                ></i>
                              </div>
                              <div class="fs-17">1,56-1,60m</div>
                            </div>
                          </div>
                          <div class="p-0 col-3">
                            <div class="tablePrint p-0 fs-17">
                              54-56kg/1700-1800kcal
                            </div>
                          </div>
                          <div class="p-0 col-2">
                            <div class="tablePrint p-0 ml-3 fs-17">
                              63-66g đạm
                            </div>
                          </div>
                        </div>

                        <div class="row p-0">
                          <div class="p-0 col-4">
                            <div class="tablePrint p-0 ml-3 flex items-center">
                              <div
                                class="box-nv mt-0 h-3 w-3 ml-3 mr-1 p-0 relative"
                              >
                                <i
                                  v-if="
                                    data.nutritional_kcal === '1800-1900' &&
                                    data.nutritional_protein === '67-70'
                                  "
                                  class="el-icon-close el-center"
                                ></i>
                              </div>
                              <div class="fs-17">1,61-1,65m</div>
                            </div>
                          </div>
                          <div class="p-0 col-3">
                            <div class="tablePrint p-0 fs-17">
                              57-59kg/1800-1900kcal
                            </div>
                          </div>
                          <div class="p-0 col-2">
                            <div class="tablePrint p-0 ml-3 fs-17">
                              67-70g đạm
                            </div>
                          </div>
                        </div>

                        <div class="row p-0">
                          <div class="p-0 col-4">
                            <div class="tablePrint p-0 ml-3 flex items-center">
                              <div
                                class="box-nv mt-0 h-3 w-3 ml-3 mr-1 p-0 relative"
                              >
                                <i
                                  v-if="
                                    data.nutritional_kcal === '1900-2000' &&
                                    data.nutritional_protein === '71-74'
                                  "
                                  class="el-icon-close el-center"
                                ></i>
                              </div>
                              <div class="fs-17">1,61-1,65m</div>
                            </div>
                          </div>
                          <div class="p-0 col-3">
                            <div class="tablePrint p-0 fs-17">
                              60-62kg/1900-2000kcal
                            </div>
                          </div>
                          <div class="p-0 col-2">
                            <div class="tablePrint p-0 ml-3 fs-17">
                              71-74g đạm
                            </div>
                          </div>
                        </div>

                        <div class="row p-0">
                          <div class="p-0 col-4">
                            <div class="tablePrint p-0 ml-3 flex items-center">
                              <div
                                class="box-nv mt-0 h-3 w-3 ml-3 mr-1 p-0 relative"
                              >
                                <i
                                  v-if="
                                    data.nutritional_kcal === '2000-2100' &&
                                    data.nutritional_protein === '75-78'
                                  "
                                  class="el-icon-close el-center"
                                ></i>
                              </div>
                              <div class="fs-17">1,71-1,75m</div>
                            </div>
                          </div>
                          <div class="p-0 col-3">
                            <div class="tablePrint p-0 fs-17">
                              63-65kg/2000-2100kcal
                            </div>
                          </div>
                          <div class="p-0 col-2">
                            <div class="tablePrint p-0 ml-3 fs-17">
                              75-78g đạm
                            </div>
                          </div>
                        </div>

                        <div class="flex p-0">
                          <div class="col-3 p-0">
                            <div
                              class="p-2 whitespace-nowrap tablePrint fs-17 ml-1"
                            >
                              Hoặc nhu cầu khác:
                            </div>
                          </div>
                          <div class="col-2 p-0">
                            <div class="p-2 pl-4 tablePrint fs-17">
                              {{ data.nutritional_kcal }} kcal;
                            </div>
                          </div>
                          <div class="col-2 p-0">
                            <div class="p-2 tablePrint fs-17">
                              {{ data.nutritional_protein }} g đạm;
                            </div>
                          </div>
                          <div class="col-2 p-0">
                            <div class="p-2 tablePrint fs-17">
                              {{ data.nutritional_sugar }} g đường;
                            </div>
                          </div>
                          <div class="col-2 p-0">
                            <div class="p-2 tablePrint fs-17">
                              {{ data.nutritional_fat }} g béo;
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="border-l border-r border-b">
                        <div class>
                          <div class="p-0 ml-1 tablePrint fs-17 font-bold">
                            6. Kế hoạch dinh dưỡng cho người bệnh
                          </div>
                        </div>
                        <div class="p-0 flex">
                          <div class="p-0 flex items-center">
                            <div
                              class="box-nv mt-0 h-3 w-3 ml-3 mr-1 p-0 relative"
                            >
                              <i
                                v-if="data.nutritional_option_01 === 1"
                                class="el-icon-close el-center"
                              ></i>
                            </div>
                            <div class="tablePrint p-0 fs-17">
                              Chỉ định mã chế độ ăn
                            </div>
                          </div>
                          <div class="p-0 flex items-center ml-20">
                            <div
                              class="box-nv mt-0 h-3 w-3 ml-3 mr-1 p-0 tablePrint fs-17 relative"
                            >
                              <i
                                v-if="data.nutritional_option_02 === 1"
                                class="el-icon-close el-center"
                              ></i>
                            </div>
                            <div class="tablePrint p-0 fs-17">
                              Qua ống thông
                            </div>
                          </div>
                        </div>
                        <div class="p-0 flex mb-3">
                          <div class="p-0 flex items-center">
                            <div
                              class="box-nv mt-0 h-3 w-3 ml-3 mr-1 p-0 relative"
                            >
                              <i
                                v-if="data.nutritional_option_03 === 1"
                                class="el-icon-close el-center"
                              ></i>
                            </div>
                            <div class="tablePrint p-0 fs-17">
                              Bổ sung thức uống DD
                            </div>
                          </div>
                          <div class="py-0 flex items-center ml-20 pl-1">
                            <div
                              class="box-nv mt-0 h-3 w-3 ml-2 mr-1 p-0 tablePrint fs-17 relative"
                            >
                              <i
                                v-if="data.nutritional_option_04 === 1"
                                class="el-icon-close el-center"
                              ></i>
                            </div>
                            <div class="tablePrint p-0 fs-17">
                              Qua tĩnh mạch
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="block mt-10">
                      <div class="col-6"></div>
                      <div class="col-6 text-center">
                        <div class="fs-17">{{ getHMDMYVN(data.date) }}</div>
                        <!-- <div v-else class="fs-17">....., ngày ...... tháng ...... năm ......</div> -->
                        <div class="font-bold fs-18 p-0">Người thực hiện</div>
                        <div class="fs-16 p-0 italic">(Ký,ghi rõ họ tên)</div>
                        <div v-show="isPrint" class="fs-17 font-bold mt-16">
                          {{ $user && $user.name }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalUpdateNhapVien
        :is_loading="is_loading"
        :data="data"
        @onUpdate="onUpdate($event)"
      />
    </div>
    <ModalSignPDF
      containerIDProps="NutritionalContainerPDF"
      elementIDProps="NutritionalSignerPDF"
      imageIDProps="NutritionalImageID"
      ref="NutritionalSignerPDF"
      :envelopeName="envelopeName"
      @onSuccess="handleSignDocumentSuccess"
    />
  </div>
</template>

  <script>
import ModalUpdateNhapVien from './ModalUpdateNhapVien.vue'
import { mapState } from 'vuex'
import appUtils from '../../../utils/appUtils'
import ModalSignPDF from '../../Signature/ModalSignPDF.vue'
import {
  ENVELOPE_DOCUMENT_TYPE,
  ENVELOPE_NAME_TYPE
} from '../../../utils/constants'
import SignatureRequest from '../../../api/request/SignatureRequest'
import { mixinSignPdf } from '../../../utils/mixinSignPdf'

export default {
  name: 'NhapVienDetail',
  components: { ModalUpdateNhapVien, ModalSignPDF },
  mixins: [mixinSignPdf],
  props: ['data'],
  data () {
    return {
      is_loading: false,
      other: null,
      other1: null,
      other2: null,
      other3: null,
      other4: null,
      other5: null,
      other6: null,
      checkEating: null,
      form: {
        name: 'PHIẾU ĐÁNH GIÁ DINH DƯỠNG CỦA BỆNH NHÂN NHẬP VIỆN',
        date: window.moment().format('YYYY-MM-DD'),
        is_off_weight: 0,
        weight_loss: null,
        anorexia: 0,
        weight_state_kg: '',
        weight_state_guyg: '',
        weight_state_month: '',
        sga: 0,
        examination_result_01: '',
        examination_result_02: '',
        examination_result_03: '',
        examination_result_04: '',
        nutritional_option_01: '',
        nutritional_option_02: '',
        nutritional_option_03: '',
        nutritional_option_04: '',
        nutritional_kcal: '',
        nutritional_sugar: '',
        nutritional_protein: '',
        nutritional_fat: '',
        eating_state: null
      },
      options: [
        {
          value: 0,
          label: '0'
        },
        {
          value: 1,
          label: '1'
        },
        {
          value: 2,
          label: '2'
        },
        {
          value: 3,
          label: '3'
        }
      ],
      result: 0,
      checkEatingDown: null,
      check: null,
      noCheck: null,
      mode: '',
      loading: false,
      total: 0,
      ageCN: 0,
      ageCC: 0,
      bmi: 0,
      ageBMI: 0,
      isPrint: false
    }
  },
  mounted () {
    let self = this
    this.$nextTick(function () {
      window.$('#modalPhieuNhapVienDetail').on('hidden.bs.modal', function (e) {
        self.mode = ''
      })
    })
  },
  computed: {
    ...mapState({
      person: (state) => state.person,
      medicalRecordMain: (state) => state.medicalRecordMain,
      emrData: (state) => state.emrData
    }),
    formatInputDateTime: {
      get () {
        return this.form.date
          ? appUtils.formatInputDateTime(this.form.date)
          : ''
      },
      set (newDate) {
        this.form.date = newDate
        return newDate
      }
    },
    envelopeName () {
      return this.handleGetEnvelopeName(ENVELOPE_NAME_TYPE.emr, this.emrData)
    }
  },
  watch: {
    data () {
      this.total =
        this.data.nutrition_control_ind1 +
        this.data.nutrition_control_ind2 +
        this.data.nutrition_control_ind3 +
        this.data.nutrition_control_ind4 +
        this.data.nutrition_control_ind5
      this.ageCN = this.data.weight / this.person.age
      this.ageCC = this.data.height / this.person.age
      const meters = this.data.height / 100
      this.bmi = this.data.weight / (meters * meters)
      this.ageBMI = this.bmi / this.person.age
      this.result =
        parseInt(this.data.is_off_weight) + parseInt(this.data.anorexia)
      this.form.name = this.data.name
      this.form.date = this.data.date
      this.form.is_off_weight = this.data.is_off_weight
      this.form.weight_loss = this.data.weight_loss
      this.form.anorexia = this.data.anorexia
      this.form.weight_state_kg = this.data.weight_state_kg
      this.form.weight_state_guyg = this.data.weight_state_guyg
      this.form.sga = this.data.sga
      this.form.nutritional_option = this.data.nutritional_option
      this.form.nutritional_kcal = this.data.nutritional_kcal
      this.form.nutritional_sugar = this.data.nutritional_sugar
      this.form.nutritional_protein = this.data.nutritional_protein
      this.form.nutritional_fat = this.data.nutritional_fat
      this.form.eating_state = this.data.eating_state
      this.result =
        parseInt(this.data.is_off_weight) + parseInt(this.data.anorexia)
      this.form.examination_result_01 = this.data.examination_result_01
      this.form.examination_result_02 = this.data.examination_result_02
      this.form.examination_result_03 = this.data.examination_result_03
      this.form.examination_result_04 = this.data.examination_result_04
      this.form.nutritional_option_01 = this.data.nutritional_option_01
      this.form.nutritional_option_02 = this.data.nutritional_option_02
      this.form.nutritional_option_03 = this.data.nutritional_option_03
      this.form.nutritional_option_04 = this.data.nutritional_option_04
      if (
        this.data.weight_state_month ||
        this.data.weight_state_kg ||
        this.data.weight_state_guyg
      ) {
        this.check = 1
      } else {
        this.noCheck = 1
      }
      if (this.data.eating_state === 2 || this.data.eating_state === 3) {
        this.checkEatingDown = 1
      }
      if (
        this.data.nutritional_kcal === '1500-1600' &&
        this.data.nutritional_protein === '55-58'
      ) {
        this.other1 = 1
      }
      if (
        this.data.nutritional_kcal === '1600-1700' &&
        this.data.nutritional_protein === '59-62'
      ) {
        this.other2 = 1
      }
      if (
        this.data.nutritional_kcal === '1700-1800' &&
        this.data.nutritional_protein === '63-66'
      ) {
        this.other3 = 1
      }
      if (
        this.data.nutritional_kcal === '1800-1900' &&
        this.data.nutritional_protein === '67-70'
      ) {
        this.other4 = 1
      }
      if (
        this.data.nutritional_kcal === '1900-2000' &&
        this.data.nutritional_protein === '71-74'
      ) {
        this.other5 = 1
      }
      if (
        this.data.nutritional_kcal === '2000-2100' &&
        this.data.nutritional_protein === '75-78'
      ) {
        this.other6 = 1
      }
    }
  },
  methods: {
    closeUpdate () {
      this.mode = ''
    },
    async onUpdate () {
      if (!this.form.date) {
        return alert('Bạn cần chọn ngày đánh giá')
      }
      const form = {
        medical_record_id: this.$route.params.id,
        ...this.form
      }
      this.is_loading = true
      await this.$rf
        .getRequest('DoctorRequest')
        .updateNutritionAdmissions(this.data.id, form)
        .then((r) => {
          this.$emit('refresh', this.data.id)
          this.$emit('refreshList')
          this.mode = ''
          this.$toast.open({
            message: 'Cập nhật thành công',
            type: 'success'
          })
        })
        .finally(() => {
          this.is_loading = false
        })
    },
    getString (value) {
      const r = Math.round(value)
      const item = r.toString().length
      if (item === 2) {
        return String(value).slice(0, 5)
      }
      if (item === 1) {
        return String(value).slice(0, 4)
      }
      if (item === 3) {
        return String(value).slice(0, 6)
      }
      if (item === 4) {
        return String(value).slice(0, 7)
      }
    },
    ModalUpload () {
      this.mode = 'update'
    },
    getBirthday () {
      if (!this.person.birthday) return ' '
      return window.moment(this.person.birthday).format('DD-MM-YYYY')
    },
    onchange1 () {
      if (this.noCheck) {
        this.form.weight_state_month = ''
        this.form.weight_state_kg = ''
        this.form.weight_state_guyg = ''
        this.check = null
      }
    },
    onchange2 () {
      this.noCheck = null
    },
    close () {
      const show = false
      window.$('#modalUploadNhapVien').modal(show ? 'show' : 'hide')
    },
    inputOther1 () {
      this.other = null
      this.form.nutritional_kcal = '1500-1600'
      this.form.nutritional_protein = '55-58'
    },
    inputOther2 () {
      this.other = null
      this.form.nutritional_kcal = '1600-1700'
      this.form.nutritional_protein = '59-62'
    },
    inputOther3 () {
      this.other = null
      this.form.nutritional_kcal = '1700-1800'
      this.form.nutritional_protein = '63-66'
    },
    inputOther4 () {
      this.other = null
      this.form.nutritional_kcal = '1800-1900'
      this.form.nutritional_protein = '67-70'
    },
    inputOther5 () {
      this.other = null
      this.form.nutritional_kcal = '1900-2000'
      this.form.nutritional_protein = '71-74'
    },
    inputOther6 () {
      this.other = null
      this.form.nutritional_kcal = '2000-2100'
      this.form.nutritional_protein = '75-78'
    },
    clearOther () {
      this.other1 = null
      this.other2 = null
      this.other3 = null
      this.other4 = null
      this.other5 = null
      this.other6 = null
      this.form.nutritional_kcal = ''
      this.form.nutritional_protein = ''
    },
    checkPoint () {
      this.result =
        parseInt(this.form.is_off_weight) + parseInt(this.form.anorexia)
      if (this.form.is_off_weight === 0) {
        this.form.weight_loss = null
      }
    },
    checkEating1 () {
      this.checkEating = null
      this.checkEatingDown = null
      this.form.eating_state = 1
    },
    checkEating2 () {
      this.checkEating = 1
    },
    async onPrintNe (isOpen = true) {
      const self = this
      const bodyHTML = self.$refs.viewPhieuNhapVien.innerHTML
      const tempHTML = appUtils.templateHTMLPrint({ bodyHTML })
      const base64Data = appUtils.base64.decode(tempHTML)
      const url = await this.savePdfToS3(base64Data, isOpen)
      return url
    },
    async savePdfToS3 (base64, isOpen = true) {
      try {
        this.loading = true

        const payload = {
          content: base64,
          fileName: 'PhieuDanhGiaDinhDuongNhapVien',
          path: 'consultations',
          type: 'test',
          type_id: 'test',
          uuid: 'test'
        }

        const res = await this.$rf
          .getRequest('DoctorRequest')
          .printDocument(payload)

        if (res?.data && isOpen) {
          window.open(res.data?.documentLink, '_blank', 'noreferrer')
        }
        this.loading = false

        return res.data?.documentLink
      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    processPrintPDF () {
      this.isPrint = true
      this.$nextTick(() => {
        this.openModalSignPDF()
      })
    },
    async openModalSignPDF () {
      this.isPrint = false

      const documentLink = await this.onPrintNe(false)

      if (!documentLink) {
        this.$toast.open({
          message: 'Không tìm thấy tài liệu cần ký',
          type: 'error'
        })
        return
      }

      const procedureResponse = await this.getSignProcedure()
      const procedureSelected = procedureResponse?.data?.[0] || {}

      const signatories = procedureSelected?.sign_nodes?.reduce((arr, node) => {
        const signs = node?.node_signatories?.map((item) => {
          let user
          if (item?.user_role === 'implementer') {
            user = {
              id: this.$user?.id,
              name: this.$user.name,
              email: this.$user.email
            }
          }

          return {
            ...item,
            user,
            user_id: user?.id
          }
        })

        return arr.concat(signs)
      }, [])

      const signerInfo = signatories?.find(
        (item) => item?.user_id === this.$user?.id
      )
      if (!signerInfo) {
        this.$toast.open({
          type: 'error',
          message: 'Bạn không có quyền ký tài liệu này'
        })

        return
      }

      this.$refs.NutritionalSignerPDF.handleOpen(
        documentLink,
        ENVELOPE_DOCUMENT_TYPE.NUTRITIONAL_FORM,
        Number(this.data.id),
        signerInfo?.user_id,
        signatories,
        null,
        procedureSelected?.id
      )
    },
    async getSignProcedure () {
      try {
        const params = {
          page_num: 1,
          page_size: 1000,
          order: 'desc',
          sort_by: 'id',
          document_type: ENVELOPE_DOCUMENT_TYPE.NUTRITIONAL_FORM,
          ws_id: this.$globalClinic?.id
        }

        const request = new SignatureRequest()

        const response = await request.getSignProcedures(params)
        return response.data
      } catch (error) {
        console.log(error)
      }
    },
    async handleSignDocumentSuccess (dataBase64, responseEnvelope) {
      try {
        this.loading = true
        const file = appUtils.convertBase64tToFile(
          dataBase64,
          parseInt(Date.now() / 1000)
        )
        const url = await this.uploadFiles(file, Number(this.data.id))
        await this.handleUpdateEnvelopeDocumentURL(responseEnvelope, url)
        window.open(url, '_blank')
        // await this.handleSignature()

        window.$('#modalPhieuNhapVienDetail').modal('hide')
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    async uploadFiles (file, id) {
      const params = {
        file,
        FolderTarget: `consultation`,
        IdTarget: id
      }
      const formData = new FormData()

      for (const key in params) {
        formData.append(key, params[key])
      }

      const res = await this.$rf
        .getRequest('DoctorRequest')
        .uploadFileTenant(formData)
      return res.data.fileUrl
    },
    async handleUpdateEnvelopeDocumentURL (responseEnvelope, url) {
      if (!responseEnvelope?.id) return

      try {
        const request = new SignatureRequest()

        const response = await request.updateEnvelope(responseEnvelope.id, {
          document_url: url
        })

        if (response.status === 200) {
          this.$toast.open({
            message: 'Ký thành công',
            type: 'success'
          })
        }
      } catch (error) {
        console.log(error)
      }
    },
    getHMDMYVN (date) {
      const dt = this.moment(date)
      // let hm = dt.clone().format('HH:mm')
      const d = dt.clone().format('DD')
      const m = dt.clone().format('MM')
      const y = dt.clone().format('YYYY')
      return 'Ngày ' + d + ' tháng ' + m + ' năm ' + y
    }
  }
}
</script>

  <style scoped lang="scss">
.mr-15 {
  margin-right: 30px;
}
.border-box {
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
}
.border-bottom-item {
  border-bottom: 1px solid black;
}
.border-right-item {
  border-right: 1px solid black;
}
.tablePrint {
  font-family: "Tinos", serif;
  color: black;
}
.box {
  border: 1px solid black;
}
</style>
